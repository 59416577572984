import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledAlert,
  Button,
} from "reactstrap";

import InputMask from "react-input-mask";

import logodark from "../../assets/images/coolCloud/Logo-Icon.png";
import logolight from "../../assets/images/logo-light.png";
// import { initForgotPassword } from "helpers/API_hendlers";

const CollectionPage = (props) => {
  const [locState, setLocalState] = useState({
    isJoin: true,
    isCheckInformed: false,
    number: "",
    loading: false,
  });
  const [error, setError] = useState(null);

  const handleSentPhone = async () => {
    setLocalState((pS) => ({ ...pS, loading: true }));
    console.log(locState);

    setTimeout(() => {
      setLocalState((pS) => ({
        ...pS,
        isCheckInformed: false,
        number: "",
        isJoin: false,
        loading: false,
      }));
    }, 2000);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-3 mt-4 text-muted">
                <Link to="#" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="100"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="100"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={7} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="p-0 mt-1">
                        {error && (
                          <UncontrolledAlert
                            color="danger"
                            className="alert-dismissible fade show"
                            role="alert"
                          >
                            <i className="mdi mdi-block-helper me-2"></i>
                            {"Couldn't resent email"}
                          </UncontrolledAlert>
                        )}

                        <p style={{ fontSize: locState.isJoin ? 16 : 18 }}>
                          {locState.isJoin
                            ? "Join our VIP SMS List for direct, exclusive communication with our Sales team"
                            : "Thank you for Joining our VIP List. We will be in contact soon!"}
                        </p>
                        {locState.isJoin && (
                          <>
                            <InputMask
                              mask="+1 (999) 999-9999"
                              name="phone"
                              style={{ textAlign: "center" }}
                              value={locState.number}
                              className="form-control input-color mb-3"
                              onChange={(e) => {
                                setLocalState({
                                  ...locState,
                                  number: e.target.value,
                                });
                              }}
                            ></InputMask>
                            <div className="form-check form-check-end mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={locState.isCheckInformed}
                                id="defaultCheck2"
                                onChange={() => {
                                  setLocalState({
                                    ...locState,
                                    isCheckInformed: !locState.isCheckInformed,
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="defaultCheck2"
                                style={{ fontSize: 11 }}
                              >
                                I want to be notified about market Product
                                specials. Please add me.
                              </label>
                            </div>
                            {locState.loading ? (
                              <Spinner className="ms-1 mb-3" color="primary" />
                            ) : (
                              <Button color="primary" onClick={handleSentPhone}>
                                Add me
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CollectionPage;
