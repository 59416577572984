import PropTypes from "prop-types";
import React, { Suspense, lazy } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
// import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  BarElement,
  LinearScale,
  Title,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  BarController,
} from "chart.js";

import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
// import NonAuthLayout from "./components/NonAuthLayout";

const VerticalLayout = lazy(() => import('./components/VerticalLayout/'));
const NonAuthLayout = lazy(() => import('./components/NonAuthLayout'));
import Loader from "components/Common/Loader";
// import VoiceCall from "components/Common/VoiceCall";
const VoiceCall = lazy(() => import('./components/Common/VoiceCall'));


// Import scss
import "./assets/scss/theme.scss";
import "./assets/css/custom.css";

ChartJS.register(
  LineController,
  BarController,
  BarElement,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale
);

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
  }
  return Layout;
};



const App = () => {
  const { layoutType, isAdmin, callModal } = useSelector(
    (state) => ({
      layoutType: state.Layout.layoutType,
      isAdmin: state.Login?.user?.is_admin,
      callModal: state.call.isShowModal,
    }),
    shallowEqual
  );

  const Layout = getLayout(layoutType);

  return (
    <Suspense fallback={<Loader />}>
      <div
        id="overlay_sidebar"
        style={{
          position: "absolute",
          display: "none",
          width: "100%",
          height: "calc(100vh - 70px)",
          top: 70,
          zIndex: 1,
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
        onClick={() => {
          var body = document.body;
          const overlay = document.getElementById("overlay_sidebar");
          overlay.style.display = "none";
          if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
          } else {
            body.classList.toggle("sidebar-enable");
          }
        }}
      />
      <Loader />
      {callModal && <VoiceCall />}
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => {
          let path = route.path;
          let component = route.component;

          if (route.path === "/") {
            if (isAdmin === 0) component = <Navigate to="/chat" />;
            if (isAdmin === 1) component = <Navigate to="/onboarding-flow" />;
            if (isAdmin === 2) component = <Navigate to="/dashboard" />;
          }

          return (
            <Route
              path={path}
              element={
                <Authmiddleware>
                  <Layout>{component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
