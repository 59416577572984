import React from "react";
import { Row, Badge, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Campaing = ({ cellProps, handleSmartSending }) => {
  const campaing = cellProps.cell.row.original;
  const flowData = cellProps.row.original;
  return (
    <Row className="campaing_column">
      {campaing?.icon && (
        <Col xs={3} className="campaing_column_img">
          <img src={campaing?.icon} />
        </Col>
      )}
      <Col className="campaing_column_info">
        <Link
          to={`/campaings/edit/${flowData.id}`}
          state={flowData}
          onClick={() => {}}
        >
          <h2>{campaing?.title}</h2>
        </Link>

        <h3>Last edited: {campaing?.modified}</h3>
        <h4>
          Send to{" "}
          <strong>
            {" "}
            {campaing?.user_list?.length > 0 ? "Selected Reps" : "All Reps"}
          </strong>
        </h4>
        {/* <Row className="smart_sending" onClick={handleSmartSending}>
          <Col xs={2}>
            <i className="mdi mdi-send"></i>
          </Col>
          <Col xs={9}>
            <p>Smart Sending On</p>
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
};

const Status = (cell) => {
  const stt = cell.cell.row.original;
  // console.log(cell);

  return (
    <>
      <p className="m-0 font-size-10">Sent: {stt.sent}</p>
      <p className="m-0 font-size-10">Delivered: {stt.delivered}</p>
      <p className="m-0 font-size-10">Total: {stt.total}</p>
    </>
  );
};

const Launched = (cell) => {
  const launched = cell.cell.row.original.launched;
  if (launched) {
    return <Badge className="bg-warning font-size-12">Launched</Badge>;
  } else {
    return <Badge className="bg-info font-size-12">Not Launched</Badge>;
  }
};

const Delivered = (cell) => {
  const delivered = cell.cell.row.original.delivered;
  return delivered ? delivered : "-";
};
const LastUpdated = (cell) => {
  const delivered = cell.cell.row.original.modified;
  return delivered ? delivered : "-";
};

const ClickRate = (cell) => {
  const clickRate = cell.cell.row.original.clickRate;
  return clickRate ? clickRate : "-";
};
const CVR = (cell) => {
  const CVR = cell.cell.row.original.CVR;
  return CVR ? CVR : "-";
};

const Unsubs = (cell) => {
  const unsubs = cell.cell.row.original.unsubs;
  return unsubs ? unsubs : "-";
};
const Revenue = (cell) => {
  const revenue = cell.cell.row.original.revenue;
  return revenue ? revenue : "-";
};

export {
  Campaing,
  Delivered,
  LastUpdated,
  ClickRate,
  CVR,
  Status,
  Unsubs,
  Revenue,
  Launched,
};
