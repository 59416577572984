import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Button,
  Alert,
} from "reactstrap";

import withRouter from "components/Common/withRouter";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { voiceRead } from "helpers/API_hendlers";
import { changeLoader } from "store/actions";

import { TEXT_EXAMPLE_READ } from "config";

import RecordAPI_wavesurfer from "helpers/waveSurfer_record";

const VoiceTraining = ({ setError }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    textToRead: "Hello",
    isOpenRecordModal: false,
  });
  const [audioUrl, setAudioUrl] = useState("");

  useEffect(() => {
    getVoice();
  }, []);

  const getVoice = () => {
    if (!state.textToRead) {
      return;
    }
    console.log("GETTING AUDIO EXAMPLE");
    dispatch(changeLoader(true));
    const data = { text: state.textToRead || TEXT_EXAMPLE_READ };
    voiceRead(data)
      .then((r) => {
        // console.log("voiceRead", r);
        setAudioUrl(r);
      })
      .catch((e) => {
        // console.log("voiceRead ERROR", e);
        setError({ isOpen: true, message: e?.msg || "Something happend" });
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  return (
    <React.Fragment>
      {audioUrl !== "" && (
        <Card>
          <CardBody>
            <Row className="mb-1 form-group">
              <Label className="form-label">
                Type text what you want to generate by AI
              </Label>
              <Input
                name="legal_business_name"
                className="form-control mb-3"
                placeholder="*"
                type="textarea"
                onChange={(e) =>
                  setState((ps) => ({
                    ...ps,
                    textToRead: e.target.value,
                  }))
                }
                value={state.textToRead}
              />
            </Row>

            <Button
              type="button"
              onClick={getVoice}
              color="primary"
              style={{ marginRight: 20, marginBottom: 20 }}
            >
              Generate Audio Sample
            </Button>

            <h4 className="card-title">Listen created template</h4>

            <audio controls src={audioUrl} style={{ width: "100%" }} />
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <Row className="">
            <Col lg="10" md="12">
              <h4 className="card-title">Record your NEW voice example.</h4>
              <Label className="form-label">
                Read please our example text or everything you want. Good audio
                total runtime is about 1-3 minutes
              </Label>
            </Col>
            <Col lg="2" md="12">
              <p className="mb-1" style={{ fontSize: 10 }}>
                Are you ready?
              </p>
              <Button
                color="primary"
                className="btn btn-primary waves-effect waves-light"
                style={{ width: "100%" }}
                onClick={() =>
                  setState((pS) => ({
                    ...pS,
                    isOpenRecordModal: true,
                  }))
                }
              >
                Start
              </Button>
            </Col>
          </Row>

          {/* <Record_API
                    isOpenRecordModal={state.isOpenRecordModal}
                    onAudioSubmit={getVoice}
                    onError={(message) =>
                      setError({ isOpen: true, message: message })
                    }
                  /> */}
        </CardBody>
      </Card>
      <RecordAPI_wavesurfer
        onLoader={(load) => dispatch(changeLoader(load))}
        isOpenRecordModal={state.isOpenRecordModal}
        onAudioSubmit={getVoice}
        onError={(message) => setError({ isOpen: true, message: message })}
      />
    </React.Fragment>
  );
};

export default withRouter(VoiceTraining);
