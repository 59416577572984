import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { isNotifBySID } from "helpers/utility";

export default function ConversationItem({
  conversation,
  changeConv,
  sidCurrentConv,
  deleteConv,
  handlePinConversation,
  nameCurrentConv,
}) {
  let correctStatusIcon = "text-light";
  if (isNotifBySID(conversation.sid)) {
    correctStatusIcon = "text-success";
  }
  const isPinned = conversation._internalState?.attributes?.isPinned;

  let isActiveConversation = false;

  if (sidCurrentConv && conversation.sid) {
    isActiveConversation = sidCurrentConv === conversation.sid;
  } else if (!conversation.sid) {
    isActiveConversation =
      nameCurrentConv ===
      `${conversation.first_name} ${conversation.last_name}`;
  }

  return (
    <li key={conversation.sid} className={isActiveConversation ? "active" : ""}>
      <Link
        to="#"
        onClick={({ target }) => {
          if (
            !target.id.includes("_pin") &&
            !target.id.includes("deleteConv")
          ) {
            if (conversation.sid) {
              changeConv(conversation.sid);
            } else {
              changeConv(
                null,
                conversation.id,
                conversation.phone,
                `${conversation.first_name} ${conversation.last_name}`
              );
            }
          }
        }}
      >
        <div className="d-flex align-items-center">
          <div className="align-self-center me-2">
            <i className={`mdi mdi-circle ${correctStatusIcon} font-size-14`} />
          </div>

          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-14 mb-1 position-relative">
              {conversation._internalState?.friendlyName ||
                `${conversation.first_name} ${conversation.last_name}`}{" "}
            </h5>

            <p className="text-truncate mb-0">{conversation.state?.current}</p>
            {!conversation.sid && (
              <div className="font-size-10" style={{ marginRight: 10 }}>
                <p className="text-truncate mb-0">{conversation.email}</p>
              </div>
            )}
          </div>

          {/* <div className="font-size-10" style={{ marginRight: 10 }}>
            <p className="text-truncate mb-0">
              Created:{" "}
              {conversation.dateCreated &&
                new Date(conversation.dateCreated).toLocaleDateString()}
            </p>
            <p className="text-truncate mb-0">Last message: </p>
            <p className="text-truncate mb-0">
              {conversation.lastMessage &&
                new Date(conversation.lastMessage?.dateCreated).toLocaleString(
                  "en-US"
                )}
            </p>
          </div> */}

          {conversation.sid && (
            <div>
              <i
                id={`${conversation.sid}_pin`}
                className={`bx ${
                  isPinned ? "bxs-pin" : "bx-pin"
                } font-size-20 pin_s`}
                onClick={() =>
                  handlePinConversation(conversation.sid, !isPinned)
                }
              />
            </div>
          )}
          {conversation.sid && (
            <div
              onClick={() =>
                deleteConv(conversation.sid, conversation.friendlyName)
              }
            >
              <i
                id="deleteConv"
                className="mdi mdi-delete text-danger font-size-20"
              />
              <UncontrolledTooltip placement="top" target="deleteConv">
                Delete this conversation
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      </Link>
    </li>
  );
}
