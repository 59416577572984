import React from "react";
import { Button, Col, Input, FormGroup } from "reactstrap";

const timePeriods = [
  { name: "minute(s)", value: "min" },
  { name: "hour(s)", value: "hour" },
  { name: "day(s)", value: "day" },
];

export const TimeDelay = ({
  id,
  onDoneEdit,
  dataForPass,
  editViewState,
  onChangeEditView,
}) => {
  console.log("editViewState", editViewState);

  return (
    <div className="editBlock" id={id}>
      <div>
        <div className="contentEdit">
          <h5>Set this delay for</h5>

          <FormGroup row>
            <Col>
              <Input
                id="exampleCheckbox"
                name="value"
                type="number"
                value={editViewState?.settings?.value}
                onChange={onChangeEditView}
              />
            </Col>
            <Col>
              <Input
                id="exampleSelect"
                name="period"
                type="select"
                style={{ fontSize: 12, backgroundColor: "#ebebeb" }}
                value={editViewState?.settings?.period}
                onChange={onChangeEditView}
              >
                {timePeriods.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.name}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <h5>after the previous step</h5>
        </div>

        <div className="info">
          <div>
            <i className="mdi mdi-lightning-bolt font-size-20"></i>
          </div>
          <p>
            Steps following this Time Delay occur on <b>Day 20</b> after the
            trigger
          </p>
        </div>
      </div>

      <Button outline onClick={onDoneEdit}>
        Save Changes
      </Button>
    </div>
  );
};
