import React, { createRef, useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Alert,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import withRouter from "components/Common/withRouter";



class Pricing extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h5 className="fw-semibold mb-3">Pricing Page</h5>

            {/* <FormGroup className="mt-3">
              <Input
                placeholder="Here will be your transcribed audio answer"
                name="text"
                type="textarea"
                value={this.state.transcribe}
                rows="5"
                disabled
              />
            </FormGroup> */}

            {/* <Button
              color="primary"
              className="btn btn-primary ms-3"
              onClick={() => this.recognition.start()}
            >
              {this.state.isListen ? "Listening..." : "Start Voice Input"}
            </Button> */}

            {/* {this.state.isListen && (
              <Button
                color="primary"
                className="btn btn-primary ms-3"
                onClick={() => this.recognition.stop()}
              >
                Stop
              </Button>
            )} */}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Pricing;
