import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import { shallowEqual, useSelector } from "react-redux";

//<span className="badge rounded-pill badge-soft-warning font-size-11">Pending</span>
//<span className="badge rounded-pill badge-soft-success font-size-11">Complete</span>
//<span className="badge rounded-pill badge-soft-secondary font-size-11">Waiting</span>
//<span className="badge rounded-pill badge-soft-primary font-size-11">Approved</span>

const STEPS = [
  {
    step: "1",
    stepName: "Brand Registration",
    status: "Begin",
    statusType: "secondary",
    link: "/brand-registration/my",
  },
  {
    step: "2",
    stepName: "Campaign Registration",
    status: "Begin",
    statusType: "secondary",
    link: "/campaign-registration/my",
  },
  {
    step: "3",
    stepName: "Connect to Shopify",
    status: "Begin",
    statusType: "secondary",
    link: "",
  },
];

function OnBoardingFlow({ router }) {
  const { isAdmin, m_service_sid, campaign_sid } = useSelector(
    (state) => ({
      isAdmin: state.Login?.user?.is_admin,
      m_service_sid: state.Login?.user?.m_service_sid,
      campaign_sid: state.Login?.user?.campaign_sid,
    }),
    shallowEqual
  );

  const [state, setState] = useState(STEPS);

  useEffect(() => {
    setState((ps) => {
      return ps.map((st) => {
        if (m_service_sid && st.stepName === "Brand Registration") {
          st.status = "Complete";
          st.statusType = "success";
          return st;
        }
        if (campaign_sid && st.stepName === "Campaign Registration") {
          st.status = "Complete";
          st.statusType = "success";
          return st;
        }

        return st
      });
    });
  }, [m_service_sid, campaign_sid]);

  return (
    <div className={`page-content`}>
      <Container fluid>
        <Breadcrumb
          title="Onboarding Flow"
          breadcrumbItems={router?.location?.pathname}
        />
        <Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-nowrap align-middle mb-0">
                <tbody>
                  {state.map((step) => (
                    <tr key={step.step}>
                      <td style={{ width: "40px" }}>
                        <div className="form-check font-size-16">
                          {step.step}
                        </div>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          <Link to="#" className="text-dark">
                            {step.stepName}
                          </Link>
                        </h5>
                      </td>
                      <td>
                        <div className="text-center">
                          <span
                            className={`badge rounded-pill badge-soft-${step.statusType} font-size-11`}
                          >
                            {step.status}
                          </span>
                        </div>
                      </td>
                      <td style={{ width: "60px" }}>
                        <div className="d-flex gap-3">
                          <Link
                            to={step.link}
                            className="text-primary"
                            onClick={() => {}}
                          >
                            <i
                              className="mdi mdi-send font-size-18"
                              id={`step_${step.step}`}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`step_${step.step}`}
                            >
                              Go to {step.stepName}
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
export default withRouter(OnBoardingFlow);
