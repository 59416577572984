import PropTypes from "prop-types";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  UncontrolledAlert,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/coolCloud/Logo-Icon.png";
import logolight from "../../assets/images/coolCloud/Logo-Icon.png";
import CarouselPage from "./CarouselPage";

import { registerUser } from "store/actions";

import withRouter from "components/Common/withRouter";

const Register2 = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      company_title: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      first_name: Yup.string().required("Please Enter Your First name"),
      last_name: Yup.string().required("Please Enter Your Last name"),
      company_title: Yup.string().required("Please Enter Company Name"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      // return;
      dispatch(registerUser(values, navigate));
    },
  });

  const { user, error, loading } = useSelector((state) => ({
    user: state.Login.user,
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-4 pt-0">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      {error && (
                        <UncontrolledAlert
                          color="danger"
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          <i className="mdi mdi-block-helper me-2"></i>
                          {error?.msg}
                        </UncontrolledAlert>
                      )}

                      <div className="mt-4">
                        <h5 className="text-primary">Register account</h5>
                        <p className="text-muted">
                          Register your company or organization for Cool Cloud
                          today.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              autoComplete=""
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">First name</Label>
                            <Input
                              name="first_name"
                              type="text"
                              placeholder="Enter your first name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Last name</Label>
                            <Input
                              name="last_name"
                              type="text"
                              placeholder="Enter your last name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name &&
                                validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                            validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <Input
                              name="company_title"
                              autoComplete=""
                              type="text"
                              placeholder="Enter your company name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company_title || ""}
                              invalid={
                                validation.touched.company_title &&
                                validation.errors.company_title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.company_title &&
                            validation.errors.company_title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.company_title}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div>
                            <p className="mb-0">
                              By registering you agree to the Cool Cloud{" "}
                              <Link
                                to="/temrs_of_use"
                                className="fw-medium text-primary"
                              >
                                Terms of Use
                              </Link>
                            </p>
                          </div>

                          <div>
                            <p className="mb-0">
                              Read our{" "}
                              <Link
                                to="/privacy_policy"
                                className="fw-medium text-primary"
                              >
                                Privacy policy
                              </Link>
                            </p>
                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary waves-effect waves-light "
                              type="submit"
                            >
                              {loading ? (
                                <Spinner className="ms-2" color="light" />
                              ) : (
                                "Register"
                              )}
                            </button>
                          </div>
                        </Form>

                        <div className="mt-1 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-1 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Cool CLoud.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register2);

Register2.propTypes = {
  history: PropTypes.object,
};
