import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CardGroup,
  Card,
  CardBody,
  Col,
  Input,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardHeader,
  CardFooter,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";
export const Target = ({ id, onDoneEdit }) => {
  return (
    <div className="editBlock" id={id}>
      <div className="contentEdit">
        <h5>FLOW TRIGGER</h5>
        <p>Metric</p>
        <Link to="#">Viewed Product</Link>
      </div>

      <div className="settings">
        <h5>TRIGGER FILTERS</h5>

        <p>Filter on property of the trigger.</p>
      </div>

      <div className="settings">
        <h5>FLOW FILTERS</h5>

        <p>Restrict the flow to only certain people</p>
      </div>

      <div className="buttons">
        <Button outline onClick={onDoneEdit}>
          Done
        </Button>
      </div>
    </div>
  );
};
