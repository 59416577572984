import React from "react";
import { phoneModifiyng } from "helpers/utility";

const CustomOption = (props) => {
  const phone = phoneModifiyng(props.data.value.phoneNumber);
  const local = `${props.data.value.locality}, ${props.data.value.region}, ${props.data.value.isoCountry}`;

  return (
    <div {...props.innerProps} className="customOptionSelect">
      <span
        style={{ width: "150px", fontSize: "15px", display: "inline-block" }}
      >
        <b>{phone}</b>
      </span>
      (
      <span style={{ fontSize: "12px", display: "inline-block" }}>{local}</span>
      )
    </div>
  );
};
export default CustomOption;

export const CustomOptionUser = (props) => {
  const email = props.data.email;
  const phone = props.data.phone;

  return (
    <div {...props.innerProps} className="customOptionSelect user">
      <div>
        <p>
          <b>
            {props.data.first_name ? props.data.first_name : "Unknown user"}
          </b>{" "}
          <b>{props.data.last_name ? props.data.last_name : ""}</b>
        </p>

        <span>
          Phone: <b>{phone}</b>, Email: <b>{email}</b>
        </span>
      </div>
      <div className="assign">Connect</div>
    </div>
  );
};
