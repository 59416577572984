import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Block_email from "./Block_email";
import BlockMessages from "./BlockMessages";

function WrapperMess({ propsM }) {
  const [tab, setTab] = useState("sms");

  return (
    <div className="w-100 user-chat">
      <Nav tabs>
        <NavItem>
          <NavLink active={tab === "email"} onClick={() => setTab("email")}>
            EMAIL
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={tab === "sms"} onClick={() => setTab("sms")}>
            SMS
          </NavLink>
        </NavItem>        
      </Nav>

      <TabContent activeTab={tab}>
        <TabPane tabId="email">
          <Block_email {...propsM} />
        </TabPane>

        <TabPane tabId="sms">
          <BlockMessages {...propsM} />
        </TabPane>       
      </TabContent>
    </div>
  );
}

export default WrapperMess;
