import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, Container, Button, Alert } from "reactstrap";
import withRouter from "components/Common/withRouter";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Onboarding = (props) => {
  const [error, setError] = useState(null);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="fw-semibold mb-3">Onboarding Page</h5>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(Onboarding);
