import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { UncontrolledTooltip, Alert, Label, Button } from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import AsyncSelect from "react-select/async";

import { updateCustomerContact } from "store/actions";
import { CustomOptionUser } from "components/Common/CustomSelectOption";
import { getShopifyContacts } from "helpers/API_hendlers";

export default function BlockShopify({ customer, setModalConnect }) {
  // console.log("BlockShopify customer", customer);
  const [state, setState] = useState({
    selectedUser: "",
    shopifyUsers: [],
    loadingShopifyUsers: false,
  });

  const [error, setError] = useState({ isShow: false, text: "" });
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    if (customer?.id && e?.customer_id) {
      dispatch(
        updateCustomerContact(customer?.id, {
          shopify_customer_id: e?.customer_id,
        })
      );
      setModalConnect(false);
    } else {
      setError({ isShow: true, text: "Unknown customer id" })
    }
  };

  const disconnect = () => {
    if (customer?.id) {
      dispatch(
        updateCustomerContact(customer?.id, {
          shopify_customer_id: null,
        })
      );
      setModalConnect(false);
    }
  };

  const promiseOptions = useCallback(
    (inputValue) =>
      new Promise((resolve) => {
        getUsers(inputValue)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => console.log(e));
      }),
    []
  );

  const getUsers = async (requestData) => {
    return new Promise((res, rej) => {
      setState((pS) => ({ ...pS, loadingShopifyUsers: true }));
      getShopifyContacts(requestData)
        .then((resp) => {
          // console.log("users available", resp);
          if (resp.customers) {
            const trest = [
              {
                first_name: "Julia",
                last_name: "fert",
                email: "tes@tesr.com",
                phone: "+155665255",
                id: "1111",
                customer_id: "1111",
              },
              {
                first_name: "Julia",
                last_name: "fert",
                email: "tes@tesr.com",
                phone: "+155665255",
                customer_id: "22",
                id: "22",
              },
              {
                first_name: "null",
                last_name: "null",
                email: "tes@tesr.com",
                phone: "+155665255",
                customer_id: "33",
                id: "33",
              },
              {
                first_name: "",
                last_name: "",
                email: "tes@tesr.com",
                phone: "+155665255",
                customer_id: "44",
                id: "44",
              },
            ];

            res(resp.customers);
            // res(trest);
          } else {
            res([]);
          }
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        })
        .finally(() => {
          setState((pS) => ({
            ...pS,
            loadingShopifyUsers: false,
          }));
        });
    });
  };

  return (
    <>
      <Alert
        isOpen={error.isShow}
        color="danger"
        role="alert"
        className="alert-dismissible fade show"
        toggle={() => {
          setError((pS) => ({
            ...pS,
            isShow: !error.isShow,
          }));
        }}
      >
        {error.text}
      </Alert>

      <div className="mb-3 position-relative">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <i
            style={{ fontSize: 20 }}
            className="mdi mdi-comment-question me-1"
            id="info_personal_number"
          />
          <UncontrolledTooltip placement="top" target="info_personal_number">
            Type email, phone, first name, last name to start searching
            customers
          </UncontrolledTooltip>
        </div>
        <div className="ajax-select mt-3 mt-lg-0 select2-container">
          <Label>Type desired customer</Label>
          <AsyncSelect
            styles={{
              menuList: (style) => ({
                ...style,
                backgroundColor: "white",
              }),
            }}
            onChange={handleSelect}
            isLoading={state.loadingShopifyUsers}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            components={{ Option: CustomOptionUser }}
          />
        </div>
        <div className="mt-2 text-end">
          <Button color="danger" onClick={disconnect}>
            Disconnect
          </Button>
        </div>
      </div>
    </>
  );
}
