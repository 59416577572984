import React from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { phoneNumberFormatter } from "helpers/utility";
import { changeLoader, updateState, updateStateCall } from "store/actions";
import BlockConvs from "./components/Block_conv";
import BlockTimeline from "./components/BlockTimeline";
import ModalCreatOrder from "./components/ModalCreateOrder";
import WrapperMess from "./components/wrapperMessages";
import BlockShopify from "./components/wrapperMessages/Block_shopify";

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileMode: {
        is: window.screen.width <= 991,
        openedList: "messages", //"conversations", // messages
      },
      modalCreateOrder: { isOpen: false, forWhat: "sms" },
      curMessage: {
        media: null,
        text: "",
        attributes: {},
        selectedFile: null,
        loading: false,
      },
      isVisible: !document.hidden,
      errors: null,
      orderLink: "",
      modalConnect: false,
    };

    this.handleAddOrderLink = this.handleAddOrderLink.bind(this);
    this.toggleCreateOrder = this.toggleCreateOrder.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.toggleMakeCallModal = this.toggleMakeCallModal.bind(this);
    this.toggleModalConnect = this.toggleModalConnect.bind(this);
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }
  componentDidUpdate(_, prevState) {
    if (prevState.isVisible !== this.state.isVisible && this.state.isVisible) {
      const chatState = ClientConversation.getConnectionState();
      // console.log("chatState", chatState);
      if (chatState !== "connected") {
        ClientConversation.close().then((r) => {
          console.log(r);
          ClientConversation.ChatToken();
        });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }
  handleVisibilityChange = (e) => {
    this.setState({ isVisible: !document.hidden });
  };

  handleAddOrderLink(orderHash, forWhat) {
    if (forWhat === "sms") {
      this.setState((pS) => ({
        ...pS,
        orderLink: `https://app.coolcloud.com/store/orderhash/${orderHash}`,
        errors: null,
      }));
    }
    if (forWhat === "email") {
      this.setState((pS) => ({
        ...pS,
        orderLink: `https://app.coolcloud.com/store/orderhash/${orderHash}`,
        errors: null,
      }));
    }
  }

  toggleCreateOrder(forWhat) {
    this.setState({
      modalCreateOrder: {
        ...this.state.modalCreateOrder,
        isOpen: !this.state.modalCreateOrder.isOpen,
        forWhat: forWhat,
      },
      errors: null,
    });
  }
  toggleMakeCallModal(phone) {
    this.props.onUpdateStatecall({ isShowModal: phone });
  }
  toggleModalConnect() {
    this.setState((ps) => ({ ...ps, modalConnect: !ps.modalConnect }));
  }

  render() {
    const { chat, contacts, router } = this.props;
    const userChosen = router?.location?.state?.user;
    const customer = contacts.customers.find(
      (c) =>
        c.phone.replace(/\D/g, "") ===
        chat.conversationProxy?._internalState?.uniqueName?.replace(/\D/g, "")
    );

    // console.log("customer chat", customer);
    const convProps = {
      isMobMode: this.state.isMobileMode,
      isMobModeChange: () => {
        this.setState((pS) => ({
          ...pS,
          isMobileMode: {
            ...pS.isMobileMode,
            openedList:
              pS.isMobileMode.openedList === "conversations"
                ? "messages"
                : "conversations",
          },
        }));
      },
      userChosen,
    };
    const messProps = {
      toggleCreateOrder: this.toggleCreateOrder,
      isMobMode: this.state.isMobileMode,
      isMobModeChange: () => {
        this.setState((pS) => ({
          ...pS,
          isMobileMode: {
            ...pS.isMobileMode,
            openedList:
              pS.isMobileMode.openedList === "conversations"
                ? "messages"
                : "conversations",
          },
        }));
      },
      errors: this.state.errors,
      makeCall: this.toggleMakeCallModal,
      customer: customer,
      orderLink: this.state.orderLink,
      setModalConnect: this.toggleModalConnect,
    };

    const clientPhone = phoneNumberFormatter(
      this.props.chat?.conversationProxy?._internalState?.uniqueName ||
        userChosen?.phone
    );
    // const isAdmin = this.props?.user?.is_admin

    return (
      <React.Fragment>
        <div className="page-content pageContent">
          <Container fluid>
            {/* <Breadcrumb
              title="Chat"
              breadcrumbItems={this.props?.router?.location?.pathname}
            /> */}
            {chat.error && JSON.stringify(chat.error) !== "{}" && (
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
                onClick={() => {
                  this.props.onUpdateState({
                    error: null,
                  });
                }}
              >
                <i className="mdi mdi-block-helper me-2"></i>
                {chat.error?.body && chat.error?.body?.message}
              </UncontrolledAlert>
            )}

            <Row>
              <Col xxl="8" xl="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar me-lg-2">
                    <div>
                      {!this.state.isMobileMode.is && (
                        <BlockConvs {...convProps} />
                      )}
                      {this.state.isMobileMode.is && (
                        <>
                          {this.state.isMobileMode.openedList ===
                          "conversations" ? (
                            <BlockConvs {...convProps} />
                          ) : (
                            <WrapperMess propsM={messProps} />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {!this.state.isMobileMode.is && (
                    <WrapperMess propsM={messProps} />
                  )}
                </div>
              </Col>
              <Col xxl={4} xl={12} style={{ paddingLeft: 0 }}>
                <BlockTimeline
                  clientPhone={clientPhone}
                  customer={customer}
                  setModalConnect={this.toggleModalConnect}
                />
              </Col>
            </Row>
          </Container>
        </div>

        <ModalCreatOrder
          isOpen={this.state.modalCreateOrder.isOpen}
          forWhat={this.state.modalCreateOrder.forWhat}
          onToggle={this.toggleCreateOrder}
          sr={this.props.user}
          customerId={customer ? customer.id : null}
          handleAddOrderLink={this.handleAddOrderLink}
        />

        <Modal
          isOpen={this.state.modalConnect}
          toggle={this.toggleModalConnect}
        >
          <ModalHeader toggle={this.toggleModalConnect}>
            Connecting user to Shopify
          </ModalHeader>
          <ModalBody>
            <BlockShopify
              customer={customer}
              setModalConnect={this.toggleModalConnect}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  user: state.Login.user,
  contacts: state.contacts,
});
const mapDispatchToProps = (dispatch) => ({
  onLoader: (payload) => dispatch(changeLoader(payload)),
  onUpdateState: (payload) => dispatch(updateState(payload)),
  onUpdateStatecall: (payload) => dispatch(updateStateCall(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
