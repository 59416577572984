import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { map } from "lodash";
import { Button, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {
  mergeConversationsCustomers,
  searchVanilla,
  statusChat,
} from "helpers/utility";
import ConversationItem from "./conversation_item";
import DeleteModal from "components/Common/DeleteModal";
import ModalCreateConversation from "./ModalCreateConversation";

function BlockConvs({ isMobMode, isMobModeChange, userChosen }) {
  const { chat, user, customers } = useSelector(
    (st) => ({
      chat: st.chat,
      user: st.Login.user,
      customers: st.contacts?.customers || [],
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState(null);
  const [modalAddConversation, setmodalAddConversation] = useState(false);
  const { correctStatusIcon, isNotConnect } = statusChat(chat.status);
  const deleteConv = async (sid) => {
    await ClientConversation.deleteConv(sid);
    setDeleteModal(null);
  };
  const handlePinConversation = (convSID, isPinned) =>
    ClientConversation.updateConvAttribute(convSID, { isPinned: isPinned });
  const toggle = () => setmodalAddConversation(!modalAddConversation);
  const changeConversation = async (
    sid,
    customerId,
    activeNotConversation,
    fN
  ) => {
    await ClientConversation.changeConversation(
      sid,
      customerId,
      activeNotConversation,
      fN
    );
    isMobModeChange();
  };

  const filteredCustomers = mergeConversationsCustomers(
    chat.conversations,
    customers
  );

  // console.log("filteredCustomers", filteredCustomers);

  return (
    <>
      <DeleteModal
        show={!!deleteModal}
        onDeleteClick={() => deleteConv(deleteModal?.sid)}
        onCloseClick={() => setDeleteModal(null)}
        title={`conversation with ${deleteModal?.name}`}
      />

      <ModalCreateConversation
        isOpen={modalAddConversation}
        onToggle={toggle}
        changeConversation={changeConversation}
      />

      <div className="pb-4 border-bottom">
        <div className="d-flex">
          <div className="align-self-center me-3">
            <img
              src={user?.image}
              className="avatar-xs rounded-circle"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-size-15 mt-0 mb-1">
              {user?.first_name} {user?.last_name}
            </h5>
            <h5 className="font-size-10 mt-0 mb-1">({user?.email})</h5>
            <p className="text-muted mb-0">
              <i
                className={`mdi mdi-circle ${correctStatusIcon} align-middle me-2`}
              />
              Status: {chat.status}
            </p>
          </div>
        </div>
        {isNotConnect && (
          <div>
            <p className="mt-2 mb-0 text-warning">
              Do not have connect to Twilio. Chat is in information mode
            </p>
            <p className="mt-2 mb-0">
              User name:{" "}
              <strong>
                {" "}
                {userChosen?.first_name} {userChosen?.last_name}
              </strong>
            </p>
            <p className="mt-2 mb-0">
              User phone: <strong> {userChosen?.phone}</strong>
            </p>
          </div>
        )}
      </div>

      <Button
        type="button"
        color="primary"
        onClick={toggle}
        className="btn btn-primary"
        style={{ width: "100%", marginTop: 5 }}
      >
        <span className=" d-sm-inline-block me-2">
          Start a new Conversation
        </span>
      </Button>

      <div className="search-box chat-search-box py-1">
        <div className="position-relative">
          <Input
            onKeyUp={() =>
              searchVanilla("search-user", "recent-list", "li", "a")
            }
            id="search-user"
            type="text"
            className="form-control"
            placeholder="Search..."
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>

      <div className="chat-leftsidebar-nav">
        <div>
          <h5 className="font-size-14 mb-1">Recent</h5>
          {!!chat.conversations.length && (
            <ul className="list-unstyled chat-list" id="recent-list">
              <PerfectScrollbar
                style={{
                  height: `calc(100vh - ${isMobMode.is ? "330px" : "400px"})`,
                }}
              >
                {map(filteredCustomers, (conversation) => (
                  <ConversationItem
                    key={conversation.sid || conversation.id}
                    conversation={conversation}
                    changeConv={changeConversation}
                    deleteConv={(sid, name) => setDeleteModal({ sid, name })}
                    handlePinConversation={handlePinConversation}
                    sidCurrentConv={chat.conversationProxy?.sid}
                    nameCurrentConv={chat.conversationProxy?.friendlyName}
                  />
                ))}
              </PerfectScrollbar>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(BlockConvs);
