import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
  UncontrolledTooltip,
  Spinner,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";

import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import {
  phoneModifiyng,
  phoneNumberFormatter,
  searchVanilla,
  parseResultVoiceMail,
} from "helpers/utility";

import {
  createVoiceMailCampaign,
  getVoiceMailCampaignById,
  getVoiceMailCampaigns,
  voiceRead,
} from "helpers/API_hendlers";

const whom = [
  { name: "All Customers", value: "allReps" },
  { name: "Selected Customers", value: "selectedReps" },
];
const descriptionMessageCreate = `If you want to add user personal details to template please use "Available Variables" feature.`;

const variables = [
  { name: "First Name", value: "first_name" },
  { name: "Last Name", value: "last_name" },
  { name: "Email", value: "email" },
  { name: "Company", value: "company" },
];

const INIT_STATE = {
  isOpenModalCampaign: null,
  loading: false,
  error: null,
  text: "Hello {first_name} {last_name}, please recall me. My number is 999-999-9999.",
  title: "",
  whomSend: "allReps",
  whomSendList: [],
};

const getList = async () => {
  try {
    const response = await getVoiceMailCampaigns();
    // console.log("getVoiceMailCampaigns", response);
    return response.voiceMailCampaigns;
  } catch (error) {
    console.log(error);
    return null;
  }
};

function VoiceCampaign(props) {
  const textRef = useRef();
  const [modalState, setModalState] = useState(INIT_STATE);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [loadingTestVoice, setLoadingTestVoice] = useState(false);
  const [list, setList] = useState([]);
  const [detailsModal, setDetailsModal] = useState(null);

  const { customers, userId } = useSelector(
    (state) => ({
      customers: state.contacts.customers,
      userId: state.Login?.user?.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    (async () => {
      setList((await getList()) || []);
    })();
  }, []);

  useEffect(() => {
    setModalState({
      ...modalState,
      whomSendList: customers.map((it) => {
        return {
          ...it,
          checked: false,
        };
      }),
    });
  }, [customers]);

  const onToggle = () =>
    setModalState({
      ...modalState,
      isOpenModalCampaign: null,
    });

  const onToggleDetails = () => setDetailsModal(null);

  const handleChange = ({ target }) => {
    setModalState((ps) => ({
      ...ps,
      error: null,
      [target.name]: target.value,
    }));
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const putVariable = (value) => {
    let positionCursor = textRef.current?.selectionStart;

    setModalState((ps) => {
      let newText = ps.text;

      if (positionCursor > newText.length) {
        positionCursor = newText.length;
      }

      const insertString = `{${value}}`;

      newText =
        newText.slice(0, positionCursor) +
        insertString +
        newText.slice(positionCursor);

      return {
        ...ps,
        error: null,
        ["text"]: newText,
      };
    });
  };

  const getVoice = () => {
    if (!modalState.text) {
      return;
    }
    setLoadingTestVoice(true);
    console.log("GETTING AUDIO EXAMPLE");
    let textByUser = modalState.text;

    variables.forEach(({ value, name }) => {
      if (textByUser.includes(`{${value}}`)) {
        textByUser = textByUser.replaceAll(`{${value}}`, name);
      }
    });

    const data = { text: textByUser };
    voiceRead(data)
      .then((r) => {
        setAudioUrl(r);
        setLoadingTestVoice(false);
      })
      .catch((e) => {
        console.log("voiceRead ERROR", e);
        setModalState((ps) => ({
          ...ps,
          error: { message: e?.msg || "Something happend" },
        }));
        setLoadingTestVoice(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("modalState", modalState);
    const { text, whomSend, whomSendList, title } = modalState;

    function generateTextByUser(user) {
      let textByUser = text;
      variables.forEach(({ value }) => {
        if (text.includes(`{${value}}`)) {
          textByUser = textByUser.replaceAll(
            `{${value}}`,
            user[value] ? user[value] : ""
          );
        }
      });
      const resp = {
        id: user.id,
        phone: phoneNumberFormatter(user.phone),
        text: textByUser,
      };
      return resp;
    }

    const listCalled =
      whomSend === "allReps"
        ? whomSendList
        : whomSendList.filter((sr) => sr.checked);

    const listCalledModifyed = listCalled.map(generateTextByUser);

    // console.log("listCalledModifyed", listCalledModifyed);

    // setModalState((ps) => ({
    //   ...ps,
    //   error: null,
    //   loading: false,
    //   isOpenModalCampaign: { listCalled, title },
    // }));

    // return;
    let message = null;
    if (!text) {
      message = "Please input message what you want to send";
    }
    if (!listCalledModifyed.length) {
      message = "At least one customer has to be selected";
    }
    if (message) {
      return setModalState({
        ...modalState,
        error: { message },
      });
    }
    // return;
    setModalState((ps) => ({
      ...ps,
      error: null,
      loading: true,
    }));

    const payload = {
      customers_list: listCalledModifyed,
      title,
    };

    createVoiceMailCampaign(userId, payload)
      .then(async (r) => {
        console.log("RESULT createVoiceMailCampaign", r);
        setModalState((ps) => ({
          ...ps,
          error: null,
          loading: false,
          isOpenModalCampaign: { listCalled, title },
        }));

        setList((await getList()) || []);
      })
      .catch((e) => {
        console.log(e);
        setModalState((ps) => ({
          ...ps,
          error: e.message || e.msg || "Something went wrong",
          loading: false,
        }));
      });
  };

  const handleUserClicks = async (campaign) => {
    try {
      const details = await getVoiceMailCampaignById(campaign.id);
      // console.log("details", details);
      setDetailsModal(details.voiceMailCampaign);
    } catch (error) {
      console.log(error);
    }
  };

  // const onClickDelete = () => {};

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        Cell: (cellProps) => {
          const campaing = cellProps.cell.row.original;
          return (
            <Col className="campaing_column_info">
              <h3 className="m-0">{campaing?.title}</h3>
            </Col>
          );
        },
      },
      {
        Header: "Created",
        filterable: true,
        Cell: (cellProps) => {
          const campaing = cellProps.cell.row.original;
          return (
            <Col className="campaing_column_info">
              <h3 className="m-0">{campaing?.created}</h3>
            </Col>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const userData = cellProps.row.original;

          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => handleUserClicks(userData)}
              >
                <i
                  className="mdi mdi-information font-size-18"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Details
                </UncontrolledTooltip>
              </Link>
              {/* <Link
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(userData)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link> */}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className={`page-content pageContent`}>
        <Container fluid>
          <Breadcrumb
            title="Voice campaign"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {modalState.error && (
            <Alert
              color="danger"
              toggle={() => {
                setModalState((ps) => ({
                  ...ps,
                  error: null,
                  loading: false,
                }));
              }}
            >
              {modalState.error?.message}
            </Alert>
          )}
          <Card>
            <CardBody>
              <Form onSubmit={onSubmit}>
                <div className="mb-3 form-group position-relative">
                  <Label className="form-label">Title</Label>
                  <Input
                    name="title"
                    className="form-control"
                    placeholder=""
                    type="text"
                    onChange={handleChange}
                    value={modalState.title}
                  />
                </div>

                <div className="mb-3 form-group position-relative">
                  <Label className="form-label">Message Content</Label>
                  <textarea
                    name="text"
                    className="form-control"
                    ref={textRef}
                    rows={3}
                    style={{
                      overflow: "auto",
                      resize: "none",
                      maxHeight: "200px",
                    }}
                    // maxLength={250}
                    onChange={handleChange}
                    value={modalState.text}
                  ></textarea>

                  <Dropdown
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "40px",
                    }}
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    direction={"down"}
                  >
                    <DropdownToggle style={{ padding: 3 }} caret>
                      Available Variables
                    </DropdownToggle>
                    <DropdownMenu>
                      {variables.map((v) => (
                        <DropdownItem
                          key={v.value}
                          onClick={() => putVariable(v.value)}
                        >
                          {v.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <i
                      style={{ fontSize: 20 }}
                      className="mdi mdi-comment-question me-1"
                      id="info_personal_number"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target="info_personal_number"
                    >
                      {descriptionMessageCreate}
                    </UncontrolledTooltip>
                  </div>
                </div>

                <div className="mb-3 form-group position-relative">
                  <div className="text-end">
                    {loadingTestVoice ? (
                      <Spinner className="ms-1" color="primary" />
                    ) : (
                      <button
                        onClick={getVoice}
                        className="btn btn-success save-user ms-2"
                      >
                        Generate Test Voice
                      </button>
                    )}
                  </div>
                </div>

                {audioUrl !== "" && (
                  <>
                    <h4 className="card-title">Listen created template</h4>
                    <audio controls src={audioUrl} style={{ width: "100%" }} />
                  </>
                )}

                <div className="mb-3 form-group">
                  <Label className="form-label">Send to:</Label>

                  <Input
                    id="exampleSelect"
                    name="whomSend"
                    type="select"
                    style={{ fontSize: 13 }}
                    value={modalState.whomSend}
                    onChange={handleChange}
                  >
                    {whom.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.name}
                      </option>
                    ))}
                  </Input>

                  {modalState.whomSend === "selectedReps" &&
                    modalState.whomSendList.length > 0 && (
                      <Row className="mt-3">
                        <div className="search-box chat-search-box py-1 mb-3">
                          <div className="position-relative">
                            <Input
                              onKeyUp={() =>
                                searchVanilla(
                                  "search_sal_rep",
                                  "whom_selr_list",
                                  "li",
                                  "p"
                                )
                              }
                              id="search_sal_rep"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                        <ul id="whom_selr_list">
                          {modalState.whomSendList.map((item) => (
                            <li
                              className="voice"
                              key={item.id}
                              onClick={() => {
                                setModalState((pS) => ({
                                  ...pS,
                                  whomSendList: pS.whomSendList.map((i) => {
                                    if (i.id === item.id) {
                                      return {
                                        ...i,
                                        checked: !i.checked,
                                      };
                                    }
                                    return i;
                                  }),
                                }));
                              }}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckcolor3"
                                  readOnly
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: 0,
                                  }}
                                  checked={item.checked}
                                />
                              </div>

                              {/* <p className="">
                            <strong>{item.name}</strong> (
                            {phoneModifiyng(item.phone)})
                          </p> */}

                              <div className="customOptionSelect voice">
                                <span
                                  style={{
                                    width: "150px",
                                    fontSize: "15px",
                                    display: "inline-block",
                                  }}
                                >
                                  <b>
                                    {item.first_name} {item.last_name}
                                  </b>
                                </span>
                                (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    display: "inline-block",
                                  }}
                                >
                                  {phoneModifiyng(item.phone)}
                                </span>
                                )
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Row>
                    )}
                </div>
                <Row>
                  <Col>
                    {modalState.loading ? (
                      <Spinner className="ms-1 mb-3" color="primary" />
                    ) : (
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Send campaign
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <TableContainer
                bordered={false}
                columns={columns}
                data={list}
                isGlobalFilter={true}
                isAddOptions={null}
                customPageSize={10}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={!!modalState.isOpenModalCampaign} toggle={onToggle}>
        <ModalHeader>
          Campaign "{modalState.isOpenModalCampaign?.title}" created
          successfully
        </ModalHeader>
        <ModalBody>
          <h4>
            {modalState.isOpenModalCampaign?.listCalled?.length} users will
            receive your voice mail.{" "}
          </h4>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <p>
            Delivery status can be tracked at Conversation page in Activity
            Timeline section
          </p>
          <Button onClick={onToggle} className="w-100">
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={!!detailsModal} toggle={onToggleDetails}>
        <ModalHeader>Campaign "{detailsModal?.title}" details</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>To</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {detailsModal?.messages &&
                detailsModal?.messages.length &&
                detailsModal?.messages.map((m) => {
                  const mesDet = parseResultVoiceMail(m?.result);

                  return (
                    <tr key={m?.id}>
                      <td>{m?.to}</td>
                      <td>
                        <p>
                          Status: <strong>{mesDet?.status}</strong>
                        </p>
                        <p>Sent: {m?.sent}</p>
                        <p>Delivery: {mesDet?.delivery}</p>
                        <p>Reason: {mesDet?.reason}</p>
                        <p>Carrier: {mesDet?.carrier}</p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={onToggleDetails} className="w-100">
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default withRouter(VoiceCampaign);
