import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "config";

import store from "./store";
import ClientConversation from "helpers/ClientConversation";
import ClientVoice from "helpers/ClientVoice";
import GoogleAPI from "helpers/GoogleAPI";


global.ClientConversation = new ClientConversation();
global.ClientVoice = new ClientVoice();
global.GoogleAPI = new GoogleAPI();

// const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
