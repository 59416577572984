import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

import logodark from "../../assets/images/coolCloud/Logo-Icon.png";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-2">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <div>
                        <img
                          src={logodark}
                          alt=""
                          height="250"
                          className="auth-logo-dark"
                        />
                      </div>

                      <div dir="ltr">
                        <Carousel
                          className="owl-carousel owl-theme auth-review-carousel slider_css"
                          id="auth-review-carousel"
                          showThumbs={false}
                        >
                          <div>
                            <h4 className="mb-0">
                              {/* <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i> */}
                              <span className="text-primary">
                                Amazing platform ROI
                              </span>
                            </h4>
                            <div className="item">
                              <div className="pb-3 pt-2">
                                <p className="font-size-16 mb-4">
                                  &quot;Cool Clouds ability to convert SMS and
                                  MMS messages into revenue beats anything I've
                                  seen or used in the market. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Jonathan Strietzel
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - CEO, Wine Chips
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              {/* <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i> */}
                              <span className="text-primary">
                                Unbelievable AI Technology
                              </span>
                            </h4>
                            <div className="item">
                              <div className="pb-3 pt-2">
                                <p className="font-size-16 mb-4">
                                  &quot;The Cool Cloud AI is trainable and
                                  learns from feedback for each sales rep. This
                                  truly allows us to scale our sales capability
                                  without adding more sales staff. &ldquo;
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Jonathan Strietzel
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - CEO, Wine Chips
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};
export default CarouselPage;
