import {
  UPDATE_STATE_FLOW,
  GET_FLOWS_SUCCESS,
  GET_FLOW_BY_ID_SUCCESS,
  EDIT_FLOW_SUCCESS,
  DELETE_FLOW_SUCCESS,
  API_ERROR_FLOWS,
  EDIT_FLOW_CONFIGURE,
} from "./actionTypes";

const flowsCreate = [
  {
    trigger: "Abandoned Cart",
    title: "Standart",
    icon: "mdi mdi-email-multiple",
    description:
      "Did you see something you liked? Convert curiosity into cash with this basic Browse Abandonment serie",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },

  {
    trigger: "HAPPY BIRTHDAY",
    title: "HAPPY BIRTHDAY",
    icon: "mdi mdi-email-multiple",
    description: "Send greeting",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },

  {
    trigger: "PRODUCT PURCHASED",
    title: "Product Purchased",
    icon: "mdi mdi-email-multiple",
    description: "Product was purchased successfully",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    trigger: "Product Shipped",
    title: "Product Shipped",
    icon: "mdi mdi-email-multiple",
    description: "Product departured from warehouse",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    trigger: "Product Delivered",
    title: "Product Delivered",
    icon: "mdi mdi-email-multiple",
    description: "Product was delivered to customer",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    trigger: "Invoice Sent",
    title: "Invoice Sent",
    icon: "mdi mdi-email-multiple",
    description: "If it is NOT an instant payment",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    trigger: "Product Viewed",
    title: "Product Viewed",
    icon: "mdi mdi-email-multiple",
    description: "poroduct viewed",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    trigger: "Product Reviewed",
    title: "Product Reviewed",
    icon: "mdi mdi-email-multiple",
    description: "product reviewed",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },

  {
    trigger: "Customer Support Ticket Filed",
    title: "Customer Support Ticket Filed",
    icon: "mdi mdi-email-multiple",
    description: "Customer Support Ticket Filed",
    tags: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
];

/**
 * Product Purchased
Product Shipped 
Product Delivered
Invoice Sent (If it is NOT an instant payment)
Order Paid For
Abandoned Cart
Product Viewed
Product Reviewed
Customer Support Ticket Filed
 */

const INIT_STATE = {
  flows_list_active: [],
  flows_list_template: [...flowsCreate],
  editFlow: null,
  error: null,
};

const FlowReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE_FLOW:
      const data = action.payload;

      const nState = { ...state };

      if (data.hasOwnProperty("flows_list_active")) {
        if (data.flows_list_active.addNew) {
          nState.flows_list_active = [
            ...nState.flows_list_active,
            data.flows_list_active.addNew,
          ];
        } else {
          nState.flows_list_active = data.flows_list_active;
        }
      }

      return nState;

    case GET_FLOWS_SUCCESS:
      return {
        ...state,
        flows_list_active: action.payload,
        error: null,
      };

    case DELETE_FLOW_SUCCESS:
      return {
        ...state,
        flows_list_active: state.flows_list_active.filter(
          ({ id }) => +id !== +action.payload
        ),
        error: null,
      };

    case GET_FLOW_BY_ID_SUCCESS:
      return {
        ...state,
        editFlow: action.payload,
        error: null,
      };

    case EDIT_FLOW_SUCCESS:
      return {
        ...state,
        editFlow: action.payload,
        error: null,
      };

    case API_ERROR_FLOWS:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default FlowReducer;
