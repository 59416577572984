import React, { useState } from "react";

import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import InputMask from "react-input-mask";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  isUseThisNumber,
  createVcard,
  custom_delay,
  constructorEmail,
} from "helpers/utility";

import { addNewCustomerContact } from "store/actions";

const INIT_STATE = {
  first_name: "",
  last_name: "",
  company: "",
  email: "",
  phone: "",
  loading: false,
  isAlreadyInUse: true,
  mode: "phone",
};

function ModalsCreateConversation({ isOpen, onToggle, changeConversation }) {
  const [state, setState] = useState(INIT_STATE);
  const [error, setError] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dispatch = useDispatch();
  const { user, customers } = useSelector(
    (st) => ({
      user: st.Login.user,
      customers: st.contacts?.customers || [],
    }),
    shallowEqual
  );

  const handleChange = ({ target }) => {
    setState((ps) => ({ ...ps, [target.name]: target.value }));
    setError(null);
  };

  const onClose = () => {
    setState(INIT_STATE);
    setError(null);
    onToggle();
  };

  const createConversation = async (e) => {
    e.preventDefault();

    try {
      const { phone, first_name, last_name, company, email } = state;
      const inviteText = `Hi ${first_name} please add my vCard to your contacts by clicking on it and saving my information to your contact book`;
      if (state.mode === "phone") {
        const check = await checkIsAlreadyExistThisNumber();
        console.log("CHECK", check);
        if (
          check?.mes ===
          "exist in customers list but do not exist in conversations"
        ) {
          setError({
            type: "info",
            message: `This number already exists in your customers list as "${check.isExistInCustomersList.first_name} ${check.isExistInCustomersList.last_name}"`,
          });
          return;
        }

        if (check === "already in use") {
          setState((ps) => ({ ...ps, isAlreadyInUse: true }));
          setError(null);
          return;
        }

        if (!check && state.isAlreadyInUse) {
          setState((ps) => ({
            ...ps,
            first_name: "",
            last_name: "",
            company: "",
            email: "",
            loading: false,
            isAlreadyInUse: false,
          }));
          setError(null);
          return;
        }

        setState((ps) => ({ ...ps, loading: true }));
        setError(null);

        await ClientConversation.createConversation({
          phone,
          first_name,
          last_name,
        });
        onClose();
        const curMessage = {
          text: inviteText,
          media: null,
          attributes: {},
          selectedFile: null,
        };

        const messageIndexN = await ClientConversation.sendMessage(curMessage);
        console.log("Invitation text messageIndex", messageIndexN);
        const { file_vcard, vcard_type, file_name } = await createVcard(
          user,
          user?.image
        );
        const curMessageVcard = {
          text: "",
          media: file_vcard,
          attributes: {},
          selectedFile: {
            type: vcard_type,
            name: file_name,
          },
        };
        await custom_delay(1000);
        const messageIndexNVcard = await ClientConversation.sendMessage(
          curMessageVcard
        );
        console.log("Invitation VCARD messageIndex", messageIndexNVcard);
      }
      if (state.mode === "email") {
        const check = await checkIsAlreadyExistThisEmail();
        console.log("checkIsAlreadyExistThisEmail", check);
        if (check?.mes === "exist in customers list") {
          setError({
            type: "info",
            message: `This email already exists in your customers list as "${check.isExistInCustomersList.first_name} ${check.isExistInCustomersList.last_name}"`,
          });
          return;
        }
        if (!check && state.isAlreadyInUse) {
          setState((ps) => ({
            ...ps,
            first_name: "",
            last_name: "",
            company: "",
            phone: "",
            loading: false,
            isAlreadyInUse: false,
          }));
          setError(null);
          return;
        }

        const { file_vcard, vcard_type, file_name } = await createVcard(
          user,
          user?.image
        );

        const isLogged = GoogleAPI.isLogged();

        const dataEmail = {
          to: state.email,
          from: "hello@coolcloud.com",
          subject: "Invite",
          text: inviteText,
          attachments: [
            {
              media: file_vcard,
              selectedFile: { type: vcard_type, name: file_name },
            },
          ],
        };

        if (isLogged) {
          dataEmail.from = isLogged.emailAddress;
          const email = await constructorEmail(dataEmail);

          await GoogleAPI.sendMessage(email);
        } else {
          await GoogleAPI.handleAuthClick()
            .then(async (r) => {
              console.log(r);
              if (r) {
                dataEmail.from = r.emailAddress;
                const email = await constructorEmail(dataEmail);
                await GoogleAPI.sendMessage(email);
              }
            })
            .catch((e) => {
              throw e;
            });
        }
      }
      const payload = {
        first_name: first_name || "",
        last_name: last_name || "",
        company: company || "",
        phone: phone || "",
        email: email || "",
      };
      dispatch(addNewCustomerContact(payload));
    } catch (error) {
      console.log(error);
      setError({
        message:
          error.messages ||
          error?.result?.error?.message ||
          "Something went wrong",
      });
      setState((ps) => ({ ...ps, loading: false }));
    }
  };

  const checkIsAlreadyExistThisNumber = () => {
    return new Promise((res, rej) => {
      try {
        console.log(state);
        const modifiedNumber = state.phone
          .replace(/\s/g, "")
          .replace(/[()-]/g, "");
        let isExistInCustomersList = customers.find(
          (c) => c.phone.replace(/\D/g, "") === state.phone.replace(/\D/g, "")
        );
        console.log("isExistInCustomersList", isExistInCustomersList);
        const isInUse = isUseThisNumber(modifiedNumber);
        if (isInUse) {
          changeConversation(isInUse);
          return res("already in use");
        }

        if (isExistInCustomersList) {
          return res({
            isExistInCustomersList,
            mes: "exist in customers list but do not exist in conversations",
          });
        }
        res();
      } catch (error) {
        console.log(error);
        res(null);
      }
    });
  };

  const checkIsAlreadyExistThisEmail = () => {
    return new Promise((res, rej) => {
      try {
        console.log(state);
        let isExistInCustomersList = customers.find(
          (c) => c.email === state.email
        );
        console.log("isExistInCustomersList", isExistInCustomersList);
        if (isExistInCustomersList) {
          return res({
            isExistInCustomersList,
            mes: "exist in customers list",
          });
        }
        res();
      } catch (error) {
        console.log(error);
        res(null);
      }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onToggle} tag="h4">
        Start a New Conversation
      </ModalHeader>
      {error && (
        <Alert
          isOpen={!!error}
          className="alert-dismissible fade show"
          role="alert"
          toggle={() => setError(null)}
          color={error.type || "danger"}
        >
          {error?.message}
        </Alert>
      )}
      {!state.isAlreadyInUse && (
        <Alert color="info">
          The {state.mode === "phone" ? "phone number" : "email"} is not in your
          customer list, you can add this contact now.
        </Alert>
      )}

      <ModalBody>
        <Form onSubmit={createConversation}>
          <Row>
            <Col xs={12}>
              {/* <Label className="form-label"></Label> */}

              <Dropdown
                setActiveFromChild
                isOpen={dropdownOpen}
                toggle={toggle}
                className="mt-2 mb-2"
              >
                <DropdownToggle caret className="nav-link">
                  {state.mode === "phone" ? "Mobile Number" : "Email Address"}{" "}
                  <i className="bx bx-caret-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      handleChange({ target: { name: "mode", value: "phone" } })
                    }
                    active={state.mode === "phone"}
                  >
                    Phone number
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleChange({ target: { name: "mode", value: "email" } })
                    }
                    active={state.mode === "email"}
                  >
                    Email address
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {state.mode === "phone" ? (
                <InputMask
                  mask="+1 (999) 999-9999"
                  name="phone"
                  value={state.phone}
                  className="form-control input-color"
                  onChange={handleChange}
                ></InputMask>
              ) : (
                <Input
                  name="email"
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                  value={state.email}
                />
              )}

              <div className="mb-3"></div>
              {!state.isAlreadyInUse && (
                <>
                  <div className="mb-3 d-flex flex-row justify-content-between">
                    <div style={{ width: "48%" }}>
                      <Label className="form-label">First name</Label>
                      <Input
                        name="first_name"
                        type="text"
                        placeholder=""
                        onChange={handleChange}
                        value={state.first_name}
                      />
                    </div>
                    <div style={{ width: "48%" }}>
                      <Label className="form-label">Last name</Label>
                      <Input
                        name="last_name"
                        type="text"
                        placeholder=""
                        onChange={handleChange}
                        value={state.last_name}
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-row justify-content-between">
                    <div style={{ width: "48%" }}>
                      <Label className="form-label">Company</Label>
                      <Input
                        name="company"
                        type="text"
                        placeholder=""
                        onChange={handleChange}
                        value={state.company}
                      />
                    </div>

                    {state.mode === "phone" && (
                      <div style={{ width: "48%" }}>
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder=""
                          onChange={handleChange}
                          value={state.email}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {state.loading ? (
                <Spinner className="ms-1 mb-3" color="primary" />
              ) : (
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Create and Send
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="btn btn-error save-user"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}

// const propsAreEqual = (prevPr, nextPr) => prevPr.isOpen === nextPr.isOpen;

export default React.memo(ModalsCreateConversation);
