import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_USAGE,
  GET_INVOICES_USAGE_SUCCESS,
  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_BALANCE,
  GET_BALANCE_SUCCESS,
  CLEAR_INVOICES_ON_LOGOUT,
  API_ERROR_INVOICE,
} from "./actionTypes";

export const getInvoices = (from, to) => ({
  type: GET_INVOICES,
  payload: { from, to },
});

export const getInvoicesSuccess = (invoices) => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
});

export const getInvoicesUsage = (from, to) => ({
  type: GET_INVOICES_USAGE,
  payload: { from, to },
});

export const getInvoicesUsageSuccess = (invoices) => ({
  type: GET_INVOICES_USAGE_SUCCESS,
  payload: invoices,
});

export const getInvoiceDetail = (invoiceId) => ({
  type: GET_INVOICE_DETAIL,
  invoiceId,
});

export const getInvoiceDetailSuccess = (invoices) => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload: invoices,
});

export const getBalance = () => ({
  type: GET_BALANCE,
});

export const getBalanceSuccess = (balance) => ({
  type: GET_BALANCE_SUCCESS,
  payload: balance,
});

export const clearInvoicesOnLogout = () => ({
  type: CLEAR_INVOICES_ON_LOGOUT,
});

export const apiErrorInvoice = (error) => {
  return {
    type: API_ERROR_INVOICE,
    payload: error,
  };
};
