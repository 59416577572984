import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledAlert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { SketchPicker } from "react-color";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import USA_states from "assets/data/USA_states";
import {
  modifySettingsCurrentUser,
  getSettingsCurrentUser,
} from "helpers/API_hendlers";

const USA_states_abbr = USA_states.map((stat) => ({
  label: stat.key,
  value: stat.value,
}));

import { modifyCurrentUser, changeLoader } from "../../store/actions";

import { formatBytes, createImageLink } from "helpers/utility";

const UserProfile = (props) => {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector(
    (state) => ({
      user: state.Login?.user,
      loading: state.Login.loading,
      error: state.Login?.error,
    }),
    shallowEqual
  );

  // const mServiceSid = user?.m_service_sid;
  const firstName = user?.first_name;
  const lastName = user?.last_name;
  const email = user?.email;
  const isAdmin = user?.is_admin;
  const registered = user?.registered;
  const phone = user?.phone;
  const address = user?.address;
  const address_2 = user?.address_2;
  const city = user?.city;
  const stateAdd = user?.state;
  const zip = user?.zip;
  const company_title = user?.company_title;
  const image = user?.image;
  const shop = user?.shop;
  const key = user?.key;
  const shopify_access_token = user?.shopify_access_token;
  const strietzel_api_key = user?.strietzel_api_key;

  const [state, setState] = useState({
    brandLogo: null,
    themeColor: user?.settings?.themeColor || "#000000",
    displayColorPicker: false,
    image: { selectedFile: null, value: "", src: image || "" },
    state: USA_states_abbr.find(({ value }) => value === stateAdd) || null,
    showAlert: {
      init: false,
      isShow: false,
      message: "",
      type: "success",
      icon: "mdi-information-outline",
    },
    localLoading: false,
  });

  useEffect(() => {
    getSettingsCurrentUser()
      .then((r) => {
        console.log(r);
        setState((pS) => ({
          ...pS,
          brandLogo: r.settings.brandLogo,
          themeColor: r.settings.themeColor,
        }));
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    // console.log("loading", loading);
    if (!loading && state.showAlert.init) {
      if (error) {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Something went wrong",
            type: "danger",
            icon: "mdi-block-helper",
            init: true,
          },
        }));
      } else {
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Updated successfully",
            type: "success",
            icon: "mdi-information-outline",
            init: true,
          },
        }));
      }
    } else {
      setState((pS) => ({
        ...pS,
        showAlert: {
          isShow: false,
          message: "Something went wrong",
          type: "danger",
          icon: "mdi-block-helper",
          init: true,
        },
      }));
    }
  }, [loading]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      // m_service_sid: mServiceSid || "",
      first_name: firstName || "",
      shopify_access_token: shopify_access_token || "",
      strietzel_api_key: strietzel_api_key || "",
      last_name: lastName || "",
      email: email || "",
      company_title: company_title || "",
      image: image || "",
      phone: phone || "",
      address: address || "",
      address_2: address_2 || "",
      city: city || "",
      state: stateAdd || "",
      zip: zip || "",
      shop: shop || "",
      key: key || "",
    },
    onSubmit: async (values) => {
      console.log("values", values)
      dispatch(changeLoader(true));
      const payload = { ...values };
      payload["state"] = state.state?.value;
      const image = await createImageLink(state.image.selectedFile);
      if (image && image.link) {
        payload.image = image.link;
      }
      dispatch(modifyCurrentUser(payload));
    },
  });

  function handleSelectGroup(selectedGroup) {
    setState((pS) => ({ ...pS, state: selectedGroup }));
  }

  const handleClick = () => {
    setState((ps) => ({ ...ps, displayColorPicker: !ps.displayColorPicker }));
  };
  const handleClose = () => {
    setState((ps) => ({ ...ps, displayColorPicker: false }));
  };

  const onBrandSettings = async (e) => {
    e.preventDefault();
    setState((ps) => ({ ...ps, localLoading: true }));
    const payload = {
      settings: { brandLogo: state.brandLogo, themeColor: state.themeColor },
    };

    if (state.brandLogo && typeof state.brandLogo !== "string") {
      const image = await createImageLink(state.brandLogo);
      if (image && image.link) {
        payload.settings.brandLogo = image.link;
        if (user.is_admin === 1) {
          dispatch(modifyCurrentUser({ image: image.link }));
        }
      }
    }

    modifySettingsCurrentUser(payload)
      .then((r) => {
        // console.log(r);
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Brand settings updated successfully",
            type: "success",
            icon: "mdi-information-outline",
            init: false,
          },
          localLoading: false,
        }));
      })
      .catch((e) => {
        console.log(e);
        setState((pS) => ({
          ...pS,
          showAlert: {
            isShow: true,
            message: "Couldn't save new brand settings",
            type: "danger",
            icon: "mdi-block-helper",
            init: false,
          },
          localLoading: false,
        }));
      });
  };

  const onSwatchHover_RGBA = (color) => {
    const format =
      "rgba(" +
      color.rgb.r +
      "," +
      color.rgb.g +
      "," +
      color.rgb.b +
      "," +
      color.rgb.a +
      ")";

    setState((ps) => ({ ...ps, themeColor: format }));
  };

  let preview = { src: "", name: "", size: 0 };
  if (state.image.selectedFile) {
    preview.src = URL.createObjectURL(state.image.selectedFile);
    preview.name = state.image.selectedFile.name;
    preview.size = formatBytes(state.image.selectedFile.size);
  }

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="User Profile"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {state.showAlert.isShow && (
            <UncontrolledAlert
              style={{ zIndex: 10000 }}
              isOpen={state.showAlert.isShow}
              color={state.showAlert.type}
              className="alert-dismissible fade show position-fixed top-0 start-50"
              role="alert"
              onClick={() => {
                setState((pS) => ({
                  ...pS,
                  showAlert: {
                    ...state.showAlert,
                    isShow: false,
                  },
                }));
              }}
            >
              <i className={`mdi ${state.showAlert.icon} me-2`}></i>
              {state.showAlert.message}
            </UncontrolledAlert>
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div
                      className=""
                      style={{
                        width: "30%",
                        maxWidth: "200px",
                        minWidth: "90px",
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <img
                        src={image || avatar}
                        alt="image company"
                        className="img-thumbnail w-100"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-4">
                      <div className="text-muted">
                        <h5>
                          {firstName} {lastName}
                        </h5>
                        <p className="mb-1">Email: {email}</p>
                        <p className="mb-1">Registered: {registered}</p>
                        <p className="mb-1">Assigned phone: {phone}</p>
                        <p className="mb-1">
                          Role: {isAdmin ? "SuperAdmin" : "User"}
                        </p>
                        {shop ? (
                          <p className="mb-1">Shopify Store: {shop}</p>
                        ) : (
                          <p className="mb-1">
                            API Key (for Shopify Store connection): {key}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title">Change Your Profile</h4>
          <p className="">
            This fields you can use for creating own vCard.vcf file
          </p>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3 form-group d-flex flex-row justify-content-between">
                  <div style={{ width: "48%" }}>
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="first_name"
                      // value={name}
                      className="form-control"
                      placeholder="*"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.first_name || ""}
                      invalid={
                        validation.touched.first_name &&
                        validation.errors.first_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.first_name &&
                    validation.errors.first_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.first_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-group" style={{ width: "48%" }}>
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="last_name"
                      // value={name}
                      className="form-control"
                      placeholder="*"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.last_name || ""}
                      invalid={
                        validation.touched.last_name &&
                        validation.errors.last_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.last_name &&
                    validation.errors.last_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.last_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    // value={name}
                    className="form-control"
                    placeholder="@"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group mb-3">
                  <Label className="form-label">Company</Label>
                  <Input
                    name="company_title"
                    disabled={!isAdmin}
                    // value={name}
                    className="form-control"
                    placeholder="Organization name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company_title || ""}
                    invalid={
                      validation.touched.company_title &&
                      validation.errors.company_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.company_title &&
                  validation.errors.company_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.company_title}
                    </FormFeedback>
                  ) : null}
                </div>
                {user.is_admin !== 0 && (
                  <>
                    <div className="form-group mb-3">
                      <Label className="form-label">Shopify API Key</Label>
                      <Input
                        name="shopify_access_token"
                        disabled={!isAdmin}
                        // value={name}
                        className="form-control"
                        placeholder="shopify api key"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.shopify_access_token || ""}
                        invalid={
                          validation.touched.shopify_access_token &&
                          validation.errors.shopify_access_token
                            ? true
                            : false
                        }
                      />
                      {validation.touched.shopify_access_token &&
                      validation.errors.shopify_access_token ? (
                        <FormFeedback type="invalid">
                          {validation.errors.shopify_access_token}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-group mb-3">
                      <Label className="form-label">Strietzel API Key</Label>
                      <Input
                        name="strietzel_api_key"
                        disabled={!isAdmin}
                        // value={name}
                        className="form-control"
                        placeholder="strietzel api key "
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.strietzel_api_key || ""}
                        invalid={
                          validation.touched.strietzel_api_key &&
                          validation.errors.strietzel_api_key
                            ? true
                            : false
                        }
                      />
                      {validation.touched.strietzel_api_key &&
                      validation.errors.strietzel_api_key ? (
                        <FormFeedback type="invalid">
                          {validation.errors.strietzel_api_key}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </>
                )}
                {user.is_admin === 0 && (
                  <div className="form-group mb-3">
                    <Label for="exampleFile">
                      Add photo{" "}
                      <span className="fs-6 fw-normal">
                        (best size for image 500px X 500px less then 5MB and
                        format .jpg)
                      </span>
                    </Label>
                    <Input
                      id="exampleFile"
                      name="image"
                      type="file"
                      onChange={(e) => {
                        const selectedImage = e.target.files[0];
                        setState((pS) => ({
                          ...pS,
                          image: {
                            ...pS.image,
                            value: e.target.value,
                            selectedFile: selectedImage,
                          },
                        }));
                      }}
                      value={state.image.value}
                    />

                    {state.image.selectedFile && (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="150"
                                alt={preview.name}
                                src={preview.src}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {preview.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{preview.size}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                    {!state.image.selectedFile && !!image && (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="150"
                                alt={image}
                                src={image}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                  </div>
                )}
                <div className="mb-3 d-flex flex-row justify-content-between">
                  <div style={{ width: "49%" }}>
                    <Label className="form-label">Address</Label>
                    <Input
                      name="address"
                      autoComplete=""
                      type="address"
                      placeholder="Insert address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address || ""}
                      invalid={
                        validation.touched.address && validation.errors.address
                          ? true
                          : false
                      }
                    />
                    {validation.touched.address && validation.errors.address ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div style={{ width: "49%" }}>
                    <Label className="form-label">Address 2</Label>
                    <Input
                      name="address_2"
                      autoComplete=""
                      type="address"
                      placeholder="Insert address 2"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.address_2 || ""}
                      invalid={
                        validation.touched.address_2 &&
                        validation.errors.address_2
                          ? true
                          : false
                      }
                    />
                    {validation.touched.address_2 &&
                    validation.errors.address_2 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.address_2}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3 d-flex flex-row justify-content-between">
                  <div style={{ width: "55%" }}>
                    <Label className="form-label">City</Label>
                    <Input
                      name="city"
                      autoComplete=""
                      type="address"
                      placeholder="City"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city || ""}
                      invalid={
                        validation.touched.city && validation.errors.city
                          ? true
                          : false
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div style={{ width: "30%", marginLeft: 5 }}>
                    <Label className="form-label">State</Label>
                    <Select
                      value={state.state || ""}
                      onChange={handleSelectGroup}
                      options={USA_states_abbr}
                      className="select2-selection"
                      styles={{
                        menuList: (style) => ({
                          ...style,
                          backgroundColor: "white",
                        }),
                      }}
                    />
                  </div>
                  <div style={{ width: "15%", marginLeft: 5 }}>
                    <Label className="form-label">Zip</Label>
                    <Input
                      name="zip"
                      autoComplete=""
                      maxLength={5}
                      type="number"
                      placeholder="Zip"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.zip || ""}
                      invalid={
                        validation.touched.zip && validation.errors.zip
                          ? true
                          : false
                      }
                    />
                    {validation.touched.zip && validation.errors.zip ? (
                      <FormFeedback type="invalid">
                        {validation.errors.zip}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="text-center mt-4">
                  {loading ? (
                    <Spinner className="ms-2" color="light" />
                  ) : (
                    <Button type="submit" color="primary">
                      Update User
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>

          {user.is_admin === 1 && (
            <>
              <h4 className="card-title">Change Brand Logo and Theme</h4>
              <Card>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={onBrandSettings}>
                    <h6 className="card-title">Theme color</h6>

                    <div className="form-group">
                      <div
                        className="input-group colorpicker-default"
                        title="Using format option"
                      >
                        <input
                          onChange={({ target }) => {
                            setState((ps) => ({
                              ...ps,
                              themeColor: target.value,
                            }));
                          }}
                          onFocus={handleClick}
                          value={state.themeColor}
                          type="text"
                          className="form-control input-lg"
                        />
                        <span
                          className="input-group-append"
                          onClick={handleClick}
                        >
                          <span className="input-group-text colorpicker-input-addon">
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                                background: state.themeColor,
                              }}
                            />
                          </span>
                        </span>
                      </div>

                      {state.displayColorPicker && (
                        <div className="popover">
                          <div
                            className="coverColorPicker"
                            onClick={handleClose}
                          />
                          <SketchPicker
                            color={state.themeColor}
                            width="220px"
                            onChange={onSwatchHover_RGBA}
                          />
                        </div>
                      )}
                    </div>

                    <h6 className="card-title mt-3">Brand logo </h6>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        const file = acceptedFiles[0];
                        setState((ps) => ({
                          ...ps,
                          brandLogo: Object.assign(file, {
                            preview: URL.createObjectURL(file),
                            formattedSize: formatBytes(file.size),
                          }),
                        }));
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                            <p className="fs-6 fw-normal">
                              (best size for image 1200px X 600px less then 5MB
                              and format .jpg)
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {state.brandLogo && typeof state.brandLogo === "string" ? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="150"
                                className="mw-100"
                                alt={"state.brandLogo"}
                                src={state.brandLogo}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : (
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="150"
                                  className="mw-100"
                                  alt={state.brandLogo?.name}
                                  src={state.brandLogo?.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {state.brandLogo?.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>
                                    {state.brandLogo?.formattedSize}
                                  </strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    )}

                    <div className="text-center mt-4">
                      {state.localLoading ? (
                        <Spinner className="ms-2" color="light" />
                      ) : (
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
