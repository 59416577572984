import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Table,
} from "reactstrap";

import LineItem from "pages/Orders/LineItem";

import { getShopProducts, postOrderByContactId } from "helpers/API_hendlers";

import {
  searchVanilla,
  mergeArraysById,
  guidGenerator,
  textGenerator,
} from "helpers/utility";

const test_data_item = {
  isSelect: false,
  img: "https://winechips.com/cdn/shop/files/milk-chocolate_c8f02a18-cf96-4511-bc3f-9dd205e2b4ea_1024x1024@2x.jpg?v=1710457294",
  title: "EASTER CHOCOLATE TRIO",
  desc: "",
  price: 29.99,
  quantity: 1,
};
// function generateList() {
//   const test_data = [];

//   for (let i = 0; i < 10; i++) {
//     test_data.push({
//       ...test_data_item,
//       variantId: guidGenerator(),
//       description: textGenerator(),
//       title: textGenerator(10),
//       price: (test_data_item.price * Math.random()).toFixed(2),
//     });
//   }

//   return test_data;
// }

export default function ModalCreatOrder(props) {
  const { isOpen, forWhat, onToggle, sr, handleAddOrderLink, customerId } =
    props;

  // console.log("forWhat", forWhat);
  const timerScroll = useRef();
  const listProductsSaved = useRef([]);

  const [state, setState] = useState({
    error: null,
    loading: false,
    previewMode: false,
    listProducts: [],
    search: "",
  });

  const [isScrolling, setScrolling] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchNewProducts();
    }
  }, [isOpen]);

  const fetchNewProducts = () => {
    setState((ps) => ({ ...ps, loading: true, listProducts: [] }));

    getShopProducts()
      .then((r) => {
        const modProducts = r.variants.map((variant) => ({
          ...variant,
          isSelect: false,
          quantity: 1,
        }));

        setState((ps) => {
          const alreadyExistList = new Map(
            ps.listProducts.map((item) => [item["variantId"], { ...item }])
          );
          modProducts.forEach((pr) => {
            if (!alreadyExistList.has(pr["variantId"])) {
              alreadyExistList.set(pr["variantId"], pr);
            }
          });
          const resultedArray = Array.from(alreadyExistList.values());
          return {
            ...ps,
            loading: false,
            listProducts: resultedArray,
          };
        });
      })
      .catch((e) => {
        console.log(e);
        // const list = generateList()
        setState((ps) => ({
          ...ps,
          loading: false,
          // listProducts: list,
          error: { message: e?.msg || "Something went wrong" },
        }));
      });
  };

  const onChangeProduct = (id_mutch, param, value) => {
    // console.log(id_mutch, param, value);
    setState((ps) => {
      return {
        ...ps,
        listProducts: ps.listProducts
          .map((item) => {
            if (item.variantId === id_mutch) {
              return { ...item, [param]: value };
            }
            return item;
          })
          .filter((i) => (ps.previewMode ? i.isSelect : true)),
      };
    });
  };

  const countTotal = () => {
    const total = state.listProducts.reduce((acc, product) => {
      return product.isSelect ? acc + product.price * product.quantity : acc;
    }, 0);
    return total.toFixed(2);
  };
  const countSelected = () => {
    return state.listProducts.filter((pr) => pr.isSelect);
  };

  const selected = countSelected();

  const onSubmit = (e) => {
    e.preventDefault();
    setState((ps) => ({ ...ps, loading: true }));
    const orderUpload = {
      initialLineItems: selected,
    };

    // handleAddOrderLink("sdfsdfsdfsdfsdf", forWhat);
    // return;

    console.log("customerId: ", customerId, orderUpload);

    if (!customerId) {
      setState((ps) => ({
        ...ps,
        loading: false,
        error: { message: "Did not recognize customer" },
      }));
      return;
    }

    if (!selected.length) {
      setState((ps) => ({
        ...ps,
        loading: false,
        error: { message: "Please choose at least one product" },
      }));
      return;
    }

    postOrderByContactId(customerId, orderUpload)
      .then((r) => {
        console.log(r);
        handleAddOrderLink(r.order.hash, forWhat);
        onToggle();
        setState((ps) => ({
          ...ps,
          loading: false,
        }));
      })
      .catch((e) => {
        setState((ps) => ({
          ...ps,
          loading: false,
          error: { message: e?.msg || "Something went wrong" },
        }));
      });
  };

  const onScrollList = ({ target }) => {
    if (state.previewMode) {
      return;
    }

    if (timerScroll.current) {
      clearTimeout(timerScroll.current);
    }
    if (!isScrolling) {
      setScrolling(true);
    }

    timerScroll.current = setTimeout(() => {
      setScrolling(false);
      clearTimeout(timerScroll.current);
    }, 500);

    if (
      target.offsetHeight + target.scrollTop + 200 > target.scrollHeight &&
      !state.loading
    ) {
      fetchNewProducts();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle} tag="h4">
        Create Order
      </ModalHeader>
      {state.error && (
        <Alert
          onClick={() =>
            setState((ps) => ({
              ...ps,
              loading: false,
              error: null,
            }))
          }
          color="danger"
        >
          {state.error?.message}
        </Alert>
      )}
      <ModalBody>
        <Row>
          <Col xs="6">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <input
                    onChange={(e) => {
                      setState((ps) => ({ ...ps, search: e.target.value }));
                    }}
                    onKeyUp={() =>
                      searchVanilla(
                        "search-products",
                        "list_products",
                        "tr",
                        "h5"
                      )
                    }
                    id="search-products"
                    type="text"
                    className="form-control"
                    placeholder={`${state.listProducts?.length} products...`}
                    value={state.search || ""}
                  />
                </label>
                <i
                  className={
                    state.loading
                      ? "bx bx-loader-circle bx-spin search-icon"
                      : "bx bx-search-alt search-icon"
                  }
                ></i>
              </div>
            </div>
          </Col>
          <Col xs="4">
            <div className="text-sm-end">
              <Button
                type="button"
                disabled={!selected.length && !state.previewMode}
                color="success"
                className="btn-rounded  mb-2 me-2 bgcp-4"
                onClick={() => {
                  setState((ps) => {
                    const newState = { ...ps };

                    if (newState.previewMode) {
                      newState.listProducts = mergeArraysById(
                        listProductsSaved.current,
                        ps.listProducts,
                        "variantId"
                      );

                      newState.previewMode = false;
                      listProductsSaved.current = [];
                    } else {
                      listProductsSaved.current = ps.listProducts;

                      newState.listProducts = selected;
                      newState.previewMode = true;
                    }

                    return newState;
                  });
                }}
              >
                {state.previewMode
                  ? "Full List"
                  : "Selected " + selected.length}
              </Button>
            </div>
          </Col>
          <Col xs="2">
            <h4 className="m-0 mb-1 font-size-14 text-center">Total:</h4>
            <p className="m-0 font-size-12">${countTotal()}</p>
          </Col>
        </Row>

        <div
          className="table-responsive"
          style={{ maxHeight: "70vh" }}
          // onScroll={onScrollList}
        >
          <Table className="table-nowrap align-middle table-hover mb-0">
            <tbody id="list_products">
              {state.listProducts.map((item, i) => (
                <LineItem
                  key={item.variantId}
                  item={item}
                  onSelectProduct={(v) =>
                    onChangeProduct(item.variantId, "isSelect", v)
                  }
                  onAmountChange={(v) =>
                    onChangeProduct(item.variantId, "quantity", v)
                  }
                />
              ))}
            </tbody>
          </Table>
        </div>
        {!isScrolling && (
          <div className="d-grid">
            <Button
              type="button"
              color="primary"
              className="btn-lg btn btn-primary"
              onClick={onSubmit}
            >
              Create Order
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
