import {
  GET_MY_AI_DATA_SUCCESS,
  CHANGE_ONE_SUCCESS,
  API_ERROR,
} from "./actionTypes";

import { INIT_MY_AI_DATA } from "config";

const INIT_STATE = {
  data: INIT_MY_AI_DATA,
  error: null,
};

const MyAIReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MY_AI_DATA_SUCCESS:
      return {
        ...state,
        data: state.data.map((training, i) => {
          training.value = action.payload[i];
          return training;
        }),
      };

    case CHANGE_ONE_SUCCESS:
      const newItems = [...state.data];
      newItems[action.payload.index].value = action.payload.value;

      return { ...state, data: newItems };

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default MyAIReducer;
