import React, { useState } from "react";

import { Link } from "react-router-dom";

import { removeOpenedConvFromNotific } from "helpers/utility";

export default function NotificationItem({ notification, setMenu }) {
  //   console.log("notification", notification);

  const title = notification.title || "Your item is shipped";
  const isMedia = notification.isMedia;

  const text = `${notification.text || ""}  ${isMedia ? "MediaFile" : ""}`;
  const time = notification.timeFromNow || "3 min ago";
  const icon = notification.icon || null;

  const goToChat = () => {
    ClientConversation.changeConversation(notification.convSID);
    setMenu(false);

    removeOpenedConvFromNotific(notification.convSID);
  };

  return (
    <Link
      to="/chat"
      className="text-reset notification-item"
      onClick={goToChat}
    >
      <div className="d-flex">
        {icon ? (
          <img
            src={icon}
            className="me-3 rounded-circle avatar-xs"
            alt="user-pic"
          />
        ) : (
          <div className="avatar-xs me-3">
            <span className="avatar-title bg-success rounded-circle font-size-16">
              <i className="bx bx-badge-check" />
            </span>
          </div>
        )}

        <div className="flex-grow-1">
          <h6 className="mt-0 mb-1">{title}</h6>
          <div className="font-size-12 text-muted">
            <p className="mb-1">{text}</p>
            <p className="mb-0">
              <i className="mdi mdi-clock-outline" /> {time}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
