/* INVOICES */
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";

export const GET_INVOICES_USAGE = "GET_INVOICES_USAGE";
export const GET_INVOICES_USAGE_SUCCESS = "GET_INVOICES_USAGE_SUCCESS";


export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";

export const CLEAR_INVOICES_ON_LOGOUT = "CLEAR_INVOICES_ON_LOGOUT";

export const API_ERROR_INVOICE = "API_ERROR_INVOICE";



