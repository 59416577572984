import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_CAMPAINGS,
  CREATE_CAMPAING,
  EDIT_CAMPAING,
  GET_CAMPAING_BY_ID,
  DELETE_CAMPAING,
} from "./actionTypes";
import {
  getCampaingsSuccess,
  createCampaingSuccess,
  editCampaingSuccess,
  getCampaingByIDSuccess,
  deleteCampaingSuccess,
  apiErrorCampaings,
} from "./actions";

import {
  getCampaings,
  createCampaing,
  editCampaing,
  getCampaingByID,
  deleteCampaing,
} from "helpers/API_hendlers";

function* getCampaingsSage() {
  try {
    const response = yield call(getCampaings);
    // console.log("getCampaingsSage", response);
    yield put(getCampaingsSuccess(response.campaigns));
  } catch (error) {
    yield put(apiErrorCampaings(error));
  }
}
function* createCampaingSage({ payload: { data, navigate } }) {
  try {
    const response = yield call(createCampaing, data);
    // console.log("createCampaingSage", response);

    if (navigate) {
      navigate("/campaings");
    }

    yield put(createCampaingSuccess());
  } catch (error) {
    yield put(apiErrorCampaings(error));
  }
}
function* editCampaingSage({ payload: { id, data, navigate } }) {
  try {
    const response = yield call(editCampaing, id, data);
    // console.log("editCampaingSage", response);
    if (navigate) {
      navigate("/campaings");
    }

    yield put(editCampaingSuccess(response.campaign));
  } catch (error) {
    yield put(apiErrorCampaings(error));
  }
}
function* getCampaingByIDSage({ payload }) {
  try {
    const response = yield call(getCampaingByID, payload);
    // console.log("getCampaingByIDSage", response);
    yield put(getCampaingByIDSuccess(response.flow));
  } catch (error) {
    yield put(apiErrorCampaings(error));
  }
}
function* deleteCampaingSage({ payload }) {
  try {
    const response = yield call(deleteCampaing, payload);
    // console.log("deleteCampaingSage", response);
    yield put(deleteCampaingSuccess(payload));
  } catch (error) {
    yield put(apiErrorCampaings(error));
  }
}

function* campaingsSaga() {
  yield takeEvery(GET_CAMPAINGS, getCampaingsSage);
  yield takeEvery(CREATE_CAMPAING, createCampaingSage);
  yield takeEvery(EDIT_CAMPAING, editCampaingSage);
  yield takeEvery(GET_CAMPAING_BY_ID, getCampaingByIDSage);
  yield takeEvery(DELETE_CAMPAING, deleteCampaingSage);
}

export default campaingsSaga;
