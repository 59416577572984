import {
  GET_MY_AI_DATA,
  GET_MY_AI_DATA_SUCCESS,
  CHANGE_ONE_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const getMyAIdata = () => ({
  type: GET_MY_AI_DATA,
});
export const getMyAIdataSuccess = (data) => ({
  type: GET_MY_AI_DATA_SUCCESS,
  payload: data,
});

export const changeOne = (index, value) => ({
  type: CHANGE_ONE_SUCCESS,
  payload: { index, value },
});

export const apiErrorMyAI = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
