import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { size, map } from "lodash";

const PaymentItem = (props) => {
  const { setting, handleClick, isActive } = props;

  return (
    // <React.Fragment>
    <Col xl="3" sm="6">
      <Card className={`text-center ${isActive ? "bg-light" : ""}`}>
        <CardBody
          className="paymentitem"
          onClick={() => handleClick(setting.name)}
        >
          <img src={setting.img} alt={setting.name} />

          {/* <h5 className="font-size-15 mb-1">
            <Link to="#" className="text-dark">
              {setting.name}
            </Link>
          </h5> */}
          {/* <p className="text-muted">{setting.designation}</p> */}

          {/* <div>
            {map(
              setting.tags,
              (tag, index) =>
                index < 2 && (
                  <Link
                    to="#"
                    className="badge bg-primary font-size-11 m-1"
                    key={"_skill_" + setting.id + index}
                  >
                    {tag}
                  </Link>
                )
            )}
            {size(setting.tags) > 2 && (
              <Link
                to="#"
                className="badge bg-primary font-size-11 m-1"
                key={"_skill_" + setting.id}
              >
                {size(setting.tags) - 1} + more
              </Link>
            )}
          </div> */}
        </CardBody>
        {/* <CardFooter className="bg-transparent border-top">
          <div className="contact-links d-flex font-size-20">
            <div className="flex-fill">
              <Link to="#" id={"message" + setting.id}>
                <i className="bx bx-message-square-dots" />
                <UncontrolledTooltip
                  placement="top"
                  target={"message" + setting.id}
                >
                  Message
                </UncontrolledTooltip>
              </Link>
            </div>
            <div className="flex-fill">
              <Link to="#" id={"project" + setting.id}>
                <i className="bx bx-pie-chart-alt" />
                <UncontrolledTooltip
                  placement="top"
                  target={"project" + setting.id}
                >
                  Projects
                </UncontrolledTooltip>
              </Link>
            </div>
            <div className="flex-fill">
              <Link to="#" id={"profile" + setting.id}>
                <i className="bx bx-user-circle" />
                <UncontrolledTooltip
                  placement="top"
                  target={"profile" + setting.id}
                >
                  Profile
                </UncontrolledTooltip>
              </Link>
            </div>
          </div>
        </CardFooter> */}
      </Card>
    </Col>
    // </React.Fragment>
  );
};

PaymentItem.propTypes = {
  setting: PropTypes.object,
};

export default PaymentItem;
