import { Device } from "@twilio/voice-sdk";

import store from "store";
import { getVoiceToken } from "./API_hendlers";

import { updateStateCall } from "store/actions";
import { phoneNumberFormatter } from "./utility";

export default class ClientVoice {
  constructor() {
    this.device = null;
    this.call = null;
    this._getVoiceToken();
    this.getDevice = this.getDevice.bind(this);
  }

  _getVoiceToken() {
    const userState = store.getState()?.Login?.user;
    let user = Object.keys(userState).length > 0 ? userState : null;

    if (!user && localStorage.getItem("authUser") !== "null") {
      const values = JSON.parse(localStorage.getItem("authUser"));
      user = values?.user;
    }

    getVoiceToken()
      .then((r) => {
        if (r.voiceToken) {
          this.initClient(r.voiceToken);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  }

  getDevice() {
    return this.device;
  }

  async initClient(voiceToken) {
    // console.log("initClient voice call");

    const options = {
      // logLevel: 1,
      // codecPreferences: ["opus", "pcmu"],
    };

    const device = new Device(voiceToken, options)
      .on("destroyed", () => {
        // console.log("destroyed");
        store.dispatch(updateStateCall({ status: "Destroyed" }));
      })
      .on("error", (call) => {
        console.log("error", call);
        store.dispatch(
          updateStateCall({ status: "error", error: "Something went wrong" })
        );
      })
      .on("incoming", (call) => {
        console.log("incoming");
        store.dispatch(
          updateStateCall({ status: "incoming", isShowModal: true })
        );
        this.forwardCall(call.connectToken, voiceToken);
      })
      .on("registered", () => {
        store.dispatch(updateStateCall({ status: "registered" }));
        // console.log("registered");
      })
      .on("registering", () => {
        store.dispatch(updateStateCall({ status: "registering" }));
        // console.log("registering");
      })
      .on("tokenWillExpire", (device) => {
        store.dispatch(updateStateCall({ status: "tokenWillExpire" }));
        // console.log("tokenWillExpire");
      })
      .on("unregistered", () => {
        store.dispatch(updateStateCall({ status: "unregistered" }));
        // console.log("unregistered");
      });

    device.audio.on("deviceChange", () => {
      // console.log("deviceChange");
    });
    device.audio.on("inputVolume", (volume) => {
      // console.log("inputVolume", volume);
    });

    await device.register();

    this.device = device;

    store.dispatch(updateStateCall({ device: device }));
  }

  async forwardCall(connectToken, voiceToken) {
    // For every incoming call, we create a new Device instance which we can
    // interact with, without affecting other calls.
    // IMPORTANT: The token for this new device needs to have the same identity
    // as the token used in the receiverDevice.
    const device = new Device(voiceToken);
    const call = await device.connect({ connectToken });

    // Destroy the device after the call is completed
    call.on("disconnect", () => device.destroy());
  }

  async makeCall(phone) {
    if (this.device) {
      let ph = phone;
      if (!ph) {
        // ph = "+14352646974"; //"+14352646974", "+380955233347"
        store.dispatch(updateStateCall({ error: "Unknown phone format" }));
        return;
      }
      store.dispatch(updateStateCall({ callStatus: "calling" }));

      const params = { To: "+" + phoneNumberFormatter(ph) };
      console.log("params call", params);
      this.call = await this.device.connect({ params });
      this.call.on("accept", () => {
        console.log("accept");
      });
      this.call.on("volume", function (inputVolume, outputVolume) {
        // console.log("inputVolume, outputVolume", inputVolume, outputVolume);
      });
      this.call.on("disconnect", () => {
        console.log("disconnect");
        store.dispatch(updateStateCall({ callStatus: "" }));
      });
      this.call.on("cancel", () => {
        console.log("cancel");
      });
      this.call.on("reject", () => {
        console.log("reject");
      });
    }
  }
  acceptIncomingCall() {
    this.call && this.call.accept();
  }
  rejectIncomingCall() {
    this.call && this.call.reject();
  }
  hangupIncomingCall() {
    this.call && this.call.disconnect();
  }
}
