import React, { memo, useEffect, useRef, useState } from "react";
import { Spinner, UncontrolledTooltip, Row, Col, Button } from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";
import withRouter from "components/Common/withRouter";
import {
  getNotificationsOrderByPhone,
  addNotesForContact,
} from "helpers/API_hendlers";
import { ORDER_TIMELINE } from "config";
import { differenceParse } from "helpers/utility";

const delayActivityTimeline = 30000;

const BlockTimeline = React.memo(
  function ActimityTimeline({ clientPhone, customer, setModalConnect }) {
    // console.log("BlockTimeline customer", customer);

    const timerID = useRef();

    const [state, setState] = useState({
      list: [],
      loading: false,
      filterBY: {
        orderName: "",
        orderId: null,
        event: null,
        limit: 10,
        offset: 0,
        isFinishList: false,
      },
    });

    const [note, setNote] = useState("");
    const [loadingNote, setLoadingNote] = useState(false);

    useEffect(() => {
      timerID.current && clearInterval(timerID.current);
      getOrderData();

      timerID.current = setInterval(getOrderData, delayActivityTimeline);
      return () => clearInterval(timerID.current);
    }, [
      clientPhone,
      state.filterBY.orderId,
      state.filterBY.event,
      state.filterBY.offset,
    ]);

    const getOrderData = () => {
      if (!clientPhone || !customer?.id) {
        return;
      }
      setState((ps) => ({ ...ps, loading: true }));

      getNotificationsOrderByPhone(customer?.id, state.filterBY)
        .then((r) => {
          // console.log("getNotificationsOrderByPhone", customer?.id, r);

          setState((ps) => {
            const newState = {
              ...ps,
              loading: false,
              filterBY: {
                ...ps.filterBY,
                isFinishList: r.orderHistory.length === 0,
              },
            };

            if (ps.filterBY.offset > 0) {
              newState.list = [...ps.list, ...r.orderHistory];
            } else {
              newState.list = r.orderHistory;
            }

            return newState;
          });
        })
        .catch((e) => {
          console.log(e);
          setState((ps) => ({ ...ps, loading: false }));
        });
    };

    const handleFilter = (e, orderId, orderName) => {
      const elID = e?.target?.id;
      // console.log("cl", elID, orderId);
      setState((ps) => {
        if (orderId) {
          return {
            ...ps,
            filterBY: {
              ...ps.filterBY,
              orderId: orderId,
              orderName: orderName,
              offset: 0,
            },
          };
        }

        if (elID === "all") {
          return {
            ...ps,
            filterBY: {
              orderName: "",
              orderId: null,
              event: null,
              limit: 10,
              offset: 0,
            },
          };
        }

        return {
          ...ps,
          filterBY: {
            ...ps.filterBY,
            event: ps.filterBY.event === elID ? null : elID,
            offset: 0,
          },
        };
      });
    };

    const addNote = () => {
      setLoadingNote(true);

      const data = { note: note };

      addNotesForContact(customer?.id, data)
        .then((r) => {
          console.log("addNotesForContact", r);
          getOrderData();
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoadingNote(false);
          setNote("");
        });
    };

    return (
      <div className="block_timeline">
        <div className="header">
          <p>Activity Timeline</p>
          <ul>
            {Object.keys(ORDER_TIMELINE).map((action) => (
              <li
                key={action}
                onClick={handleFilter}
                id={action}
                className={state.filterBY.event === action ? "active" : ""}
                title={ORDER_TIMELINE[action].name}
              >
                <i className={ORDER_TIMELINE[action].icon} id={action}></i>
                {/* <UncontrolledTooltip placement="top" target={action}>
                {ORDER_TIMELINE[action].name}
              </UncontrolledTooltip> */}
              </li>
            ))}

            <li
              onClick={getOrderData}
              className={state.loading ? "load" : ""}
              title="Refresh"
            >
              <Spinner size="sm" />
              <i className="mdi mdi-refresh" id="refresh"></i>
              {/* <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip> */}
            </li>
          </ul>
        </div>

        {!customer?.shopify_customer_id && (
          <p className="font-size-15 m-0 text-center p-1">
            To see Activity from Shopify Store, you must{" "}
            <span className={"handled"} onClick={() => setModalConnect(true)}>
              Connect
            </span>{" "}
            this account.
          </p>
        )}

        {(state.filterBY.event || state.filterBY.orderName) && (
          <div className="filter">
            Filter By: <strong>{state.filterBY.orderName} </strong>
            <strong>{ORDER_TIMELINE[state.filterBY.event]?.name}</strong>
          </div>
        )}

        <div className="pt-3 chat-input-section position-relative mb-2">
          <Row className="align-items-center">
            <Col>
              <div className="position-relative">
                <textarea
                  type="textarea"
                  rows={1}
                  style={{
                    overflow: "auto",
                    resize: "none",
                    maxHeight: "200px",
                  }}
                  value={note}
                  onChange={({ target }) => setNote(target.value)}
                  className="form-control chat-input"
                  placeholder="Add Note"
                />
              </div>
            </Col>
            <Col className="col-auto">
              <Button
                type="button"
                color="primary"
                onClick={addNote}
                className="btn btn-primary btn-rounded chat-send w-md "
              >
                {loadingNote ? (
                  <Spinner className="m-0 p-0" color="info" size="sm" />
                ) : (
                  <span className="d-none d-sm-inline-block me-2">
                    Add Note
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </div>

        <div
          className="content_timeline"
          style={!state.list.length ? { height: 0 } : {}}
          onScroll={(r) => {
            if (
              r.target.scrollTop + r.target.clientHeight ===
              r.target.scrollHeight - 1
            ) {
              // console.log('scroll end')
              if (!state.filterBY.isFinishList) {
                setState((ps) => ({
                  ...ps,
                  filterBY: {
                    ...ps.filterBY,
                    offset: ps.filterBY.offset + ps.filterBY.limit,
                  },
                }));
              }
            }
          }}
        >
          <div className="tree">
            <div className="line_tree" />
            <ul className="months">
              {state.list.map((m) => {
                const time = m.json.updated_at || m.added || new Date();

                return (
                  <li
                    key={m.id}
                    onClick={(e) => handleFilter(e, m.json.id, m.json.name)}
                  >
                    <h3>{new Date(time).toDateString()}</h3>
                    <ul className="activities">
                      <li key={m.id}>
                        <div className="icon">
                          <i
                            className={
                              ORDER_TIMELINE[m.event]?.icon || "bx bxs-home"
                            }
                          ></i>
                        </div>
                        <TemplateGenerator m={m} />
                      </li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  },
  (p, n) => {
    // console.log("prevProps", p);
    // console.log("nextProps", n);

    if (p.clientPhone === n.clientPhone) {
      return true;
    }
    if (p.customer?.id === n.customer?.id) {
      return true;
    }

    return false;
  }
);

// const propsAreEqual = (prevPr, nextPr) =>
//   prevPr.clientPhone === nextPr.clientPhone ||
//   prevPr.customer?.shopify_customer_id === nextPr.customer?.shopify_customer_id;

export default withRouter(BlockTimeline);

// export default memo(withRouter(BlockTimeline), propsAreEqual);

function TemplateGenerator({ m }) {
  // console.log("m", m);

  const isInternalNote = m?.event === "internalNoteAdded";
  const isVoiceMail = m?.event === "orderVoiceMail";

  const time = m.json.updated_at || m.added || new Date();

  return (
    <div className="data">
      <h2 id={`filter_by_number_${m.id}`}>
        {ORDER_TIMELINE[m.event]?.name || "Order updated"}
      </h2>
      <h3>
        <i className="mdi mdi-clock-time-three-outline"></i>
        {new Date(time).toLocaleTimeString()}{" "}
        {!isInternalNote &&
          !isVoiceMail &&
          `by ${m.json?.customer?.first_name} ${m.json?.customer?.last_name} (${m.json?.customer?.email})`}
        {isInternalNote && "by me"}
        {isVoiceMail && `call to +1${m.json.to}`}
      </h3>

      {!isInternalNote && !isVoiceMail && (
        <>
          <h4 id={`filter_by_number_${m.id}`} className="order_number">
            Order number: {m.json.name};
            <UncontrolledTooltip
              placement="top"
              target={`filter_by_number_${m.id}`}
            >
              Filter by {m.json.name}
            </UncontrolledTooltip>
          </h4>
          <h4>
            Order price: {m.json.total_price} {m.json.presentment_currency};
          </h4>
        </>
      )}

      {isVoiceMail && (
        <>
          <h4 className="order_number">Status: {m.json.status};</h4>
          <h4>Reason: {m.json.reason};</h4>
          <h4>Carrier: {m.json.carrier};</h4>
        </>
      )}

      {m.json.note && (
        <h4>
          {" "}
          {isInternalNote ? "Note: " : "Order note: "}
          {m.json.note};
        </h4>
      )}

      {m.difference && (
        <>
          <h4>Difference:</h4>
          {differenceParse(m.difference).map((f, index) => (
            <h4 className="ms-2 text-truncate" key={index}>
              {f.trim()}
            </h4>
          ))}
        </>
      )}
    </div>
  );
}
