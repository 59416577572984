import { getDataMesGoogle } from "helpers/utility";

// const CLIENT_ID =
//   "872663326631-3mbrdtpap0ledrk8e8k5h65l8nt3q34k.apps.googleusercontent.com"; // dev sergii.podlishchuk@quadecco.com
// const API_KEY = "AIzaSyBiOOdvUMHbHBABwKMgpBOVzbuM5Klyzso"; // dev sergii.podlishchuk@quadecco.com
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES;
const DISCOVERY_DOC = process.env.REACT_APP_GOOGLE_DISCOVERY_DOC;

class GoogleAPI {
  constructor() {
    this.tokenClient = null;
    const storedUser = localStorage.getItem("loggedUser");
    this.loggedUser = storedUser ? JSON.parse(storedUser) : null;
    this.handleAuthClick = this.handleAuthClick.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.init();
  }
  isLogged() {
    return this.loggedUser;
  }
  init() {
    window.gapi.load("client", async (r) => {
      const storedToken = localStorage.getItem("oauthToken");
      if (storedToken) {
        window.gapi.client.setToken(JSON.parse(storedToken));
      }
      await window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      this.tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: "",
      });
    });
  }
  handleAuthClick() {
    return new Promise((res, rej) => {
      try {
        this.tokenClient.callback = async (resp) => {
          try {
            if (resp?.error !== undefined) throw resp;
            const userInfo = await window.gapi.client.gmail.users.getProfile({
              userId: "me",
            });
            localStorage.setItem("loggedUser", JSON.stringify(userInfo.result));
            const token = window.gapi.client.getToken();
            localStorage.setItem("oauthToken", JSON.stringify(token));
            this.loggedUser = userInfo.result;
            res(userInfo.result);
          } catch (error) {
            console.log(error);
            res(null);
          }
        };
        if (window.gapi.client.getToken() === null) {
          this.tokenClient.requestAccessToken({ prompt: "consent" });
        } else {
          this.tokenClient.requestAccessToken({ prompt: "" });
        }
      } catch (error) {
        console.log(error);
        res(null);
      }
    });
  }
  handleSignoutClick() {
    const token = window.gapi.client.getToken();
    if (token !== null && window.google) {
      window.google?.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken("");
    }
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("oauthToken");

    this.loggedUser = null;
    return true;
  }
  sendMessage(email, threadId) {
    return new Promise((res, rej) => {
      const options = {
        userId: "me",
        resource: {
          raw: email,
        },
      };
      if (threadId) {
        options.resource["threadId"] = threadId;
      }

      window.gapi.client.gmail.users.messages
        .send(options)
        .then((r) => res(r))
        .catch((e) => rej(e));
    });
  }
  getMessages(customer) {
    return new Promise(async (res, rej) => {
      try {
        if (!customer || !customer.email) return res([]);

        const inboxOptions = {
          userId: "me",
          maxResults: 20,
          labelIds: ["INBOX"],
        };

        const sentOptions = {
          userId: "me",
          maxResults: 20,
          labelIds: ["SENT"],
        };

        if (customer && customer.email) {
          inboxOptions["q"] = `from:${customer.email}`;
          sentOptions["q"] = `to:${customer.email}`;
        }

        const inboxResponse =
          await window.gapi.client.gmail.users.messages.list(inboxOptions);
        const inboxMessages = inboxResponse.result.messages || [];

        const sentResponse = await window.gapi.client.gmail.users.messages.list(
          sentOptions
        );
        const sentMessages = sentResponse.result.messages || [];

        const commonMessages = [...inboxMessages, ...sentMessages];
        const threads = new Map();

        commonMessages.forEach((mes) => {
          !threads.has(mes.threadId)
            ? threads.set(mes.threadId, mes)
            : threads.set(mes.threadId, { ...mes, isThread: true });
        });

        const messages = Array.from(threads.values());

        if (messages && messages.length > 0) {
          const messageDetails = await Promise.all(
            messages.map(async (message) => {
              const msg = await window.gapi.client.gmail.users.messages.get({
                userId: "me",
                id: message.id,
              });
              const parsedBody = getDataMesGoogle(
                msg.result.payload,
                message.id
              );
              return { ...msg.result, parsedBody, isThread: message.isThread };
            })
          );
          messageDetails.sort((a, b) => b.internalDate - a.internalDate);
          res(messageDetails);
        } else {
          res([]);
        }
      } catch (err) {
        console.log("getMessages ERROR", err);
        rej(err);
      }
    });
  }
  getMessagesFromThread(threadId) {
    return new Promise(async (res, rej) => {
      try {
        const threadResponse = await window.gapi.client.gmail.users.threads.get(
          {
            userId: "me",
            id: threadId,
          }
        );
        const messages = threadResponse.result?.messages;
        if (messages && messages.length > 0) {
          const messageDetails = await Promise.all(
            messages.map(async (m) => ({
              ...m,
              parsedBody: getDataMesGoogle(m.payload, m.id),
            }))
          );
          messageDetails.sort((a, b) => b.internalDate - a.internalDate);
          res({ messageDetails, commonThreadData: messageDetails[0] });
        } else {
          res([]);
        }
      } catch (err) {
        console.log("getMessagesFromThread ERROR", err);
        rej(err);
      }
    });
  }
}

export default GoogleAPI;
