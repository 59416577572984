export const UPDATE_STATE = "UPDATE_STATE";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const REQUEST_VOICE_MAILS = "REQUEST_VOICE_MAILS";
export const REQUEST_VOICE_MAILS_SUCCESS = "REQUEST_VOICE_MAILS_SUCCESS";
export const UPDATE_VOICE_MAILS_STATUS = "UPDATE_VOICE_MAILS_STATUS";
export const UPDATE_VOICE_MAILS_STATUS_SUCCESS = "UPDATE_VOICE_MAILS_STATUS_SUCCESS";

export const API_ERROR = "CHAT_API_ERROR";



