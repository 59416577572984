import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector, shallowEqual } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { map } from "lodash";
import NotificationItem from "./NotificationDropDownItem";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const { notificationsChanged } = useSelector(
    (state) => ({
      notificationsChanged: state.chat.notifications,
    }),
    shallowEqual
  );
  // console.log("notificationsChanged", notificationsChanged);

  let notifications = [];
  const locNot = localStorage.getItem("notifications");

  if (locNot) {
    notifications = JSON.parse(locNot);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell cp-2 ${
              notifications.length > 0 ? "bx-tada" : ""
            }`}
          />
          <span
            className={`badge bg-${
              notifications.length > 0 ? "danger" : "white"
            } rounded-pill`}
          >
            {notifications.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.length > 0 ? (
              map(notifications, (notification, index) => (
                <NotificationItem
                  key={notification?.messageSID + index}
                  notification={notification}
                  setMenu={setMenu}
                />
              ))
            ) : (
              <div className="text-center text-info">
                You currently have no notifications
              </div>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);
