import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Container, Card, CardBody, UncontrolledTooltip } from "reactstrap";

import withRouter from "components/Common/withRouter";
import Breadcrumb from "components/Common/Breadcrumb";
import { isUseThisNumber } from "helpers/utility";
import { updateVoiceMailStatus } from "store/actions";

function VoiceMail(props) {
  const [modalState, setModalState] = useState({
    isOpenModalCampaign: false,
    loading: false,
    error: null,
    text: "Hello {userName}, please recall me. My number is 999-999-9999.",
    whomSend: "allReps",
    whomSendList: [],
  });

  const dispatch = useDispatch();
  const { voiceMails, customers, userId } = useSelector(
    (state) => ({
      voiceMails: state.chat.voiceMails,
      customers: state.contacts.customers,
      userId: state.Login?.user?.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    setModalState({
      ...modalState,
      whomSendList: customers.map((it) => {
        return {
          checked: false,
          name: `${it.first_name} ${it.last_name}`,
          phone: it.phone,
          id: it.id,
        };
      }),
    });
  }, [customers]);

  const onToggle = () =>
    setModalState({
      ...modalState,
      isOpenModalCampaign: !modalState.isOpenModalCampaign,
    });

  return (
    <React.Fragment>
      <div className={`page-content`}>
        <Container fluid>
          <Breadcrumb
            title="Voice mails"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          <Card>
            <CardBody>
              <div className="table-responsive voice_mail_container">
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                    {voiceMails.map((record, ind) => {
                      const customerData = customers.find(
                        ({ phone }) =>
                          phone.replace(/\s/g, "").replace(/[()-]/g, "") ===
                          record.From
                      );

                      return (
                        <tr
                          key={record.RecordingSid + ind}
                          className={`voicemail_item ${
                            record.recording_listened ? "listened" : ""
                          }`}
                        >
                          <td width={"50px"}>
                            <div
                              className="border d-flex flex-column align-items-center p-2"
                              id={"make_as_listened" + record.RecordingSid}
                              onClick={() => {
                                if (!record.recording_listened) {
                                  dispatch(
                                    updateVoiceMailStatus(
                                      userId,
                                      record.RecordingSid
                                    )
                                  );
                                }
                              }}
                            >
                              {record.recording_listened ? (
                                <i className="mdi mdi-check-bold font-size-24 text-success"></i>
                              ) : (
                                <i className="mdi mdi-close-thick font-size-24 text-danger"></i>
                              )}

                              <p className="m-0">
                                {record.recording_listened
                                  ? "played"
                                  : "unplayed"}
                              </p>
                              <UncontrolledTooltip
                                placement="top"
                                target={
                                  "make_as_listened" + record.RecordingSid
                                }
                              >
                                Make as Listened
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <p className="mb-0">
                              From:{" "}
                              {customerData && customerData.first_name
                                ? customerData.first_name +
                                  customerData.last_name
                                : record.From}
                            </p>
                            {/* <p className="mb-0">To: {record.To}</p> */}
                            <audio
                              style={{ width: "100%" }}
                              controls={true}
                              src={record.RecordingUrl + ".mp3"}
                              onEnded={() =>
                                dispatch(
                                  updateVoiceMailStatus(
                                    userId,
                                    record.RecordingSid
                                  )
                                )
                              }
                            />
                          </td>
                          {/* <td style={{ width: "40px" }}>
                            <div className="d-flex gap-3">
                              {record.recording_listened ? (
                                <div>
                                  <i
                                    className="mdi mdi-ear-hearing-off font-size-18"
                                    id={
                                      "make_as_listened" + record.RecordingSid
                                    }
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={
                                      "make_as_listened" + record.RecordingSid
                                    }
                                  >
                                    Already Listened
                                  </UncontrolledTooltip>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    dispatch(
                                      updateVoiceMailStatus(
                                        userId,
                                        record.RecordingSid
                                      )
                                    )
                                  }
                                >
                                  <i
                                    className="mdi mdi-ear-hearing font-size-18"
                                    id={
                                      "make_as_listened" + record.RecordingSid
                                    }
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={
                                      "make_as_listened" + record.RecordingSid
                                    }
                                  >
                                    Make as Listened
                                  </UncontrolledTooltip>
                                </div>
                              )}
                            </div>
                          </td> */}

                          <td style={{ width: "40px" }}>
                            {customerData && (
                              <div className="d-flex gap-3">
                                <Link
                                  to="/chat"
                                  className="text-primary"
                                  onClick={() => {
                                    const isUse = isUseThisNumber(record.From);
                                    // console.log(userData, "isUse", isUse);

                                    if (
                                      isUse &&
                                      customerData.conversation_sid
                                    ) {
                                      ClientConversation.changeConversation(
                                        isUse,
                                        customerData.conversation_sid
                                          ? null
                                          : customerData.id
                                      );
                                    } else {
                                      ClientConversation.createConversation({
                                        phone: customerData.phone,
                                        first_name:
                                          customerData.first_name || "",
                                        last_name: customerData.last_name || "",
                                        contactID: customerData.id,
                                      });
                                    }
                                  }}
                                >
                                  <i
                                    className="mdi mdi-send font-size-18"
                                    id="go_to_chat"
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="go_to_chat"
                                  >
                                    Go to Chat
                                  </UncontrolledTooltip>
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withRouter(VoiceMail);
