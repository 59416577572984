// Record plugin
import React from "react";
import { Row, Col, Spinner, Button, Label, Card, CardBody } from "reactstrap";

import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

import { TEXT_EXAMPLE_READ } from "config";

import { voiceCreate } from "./API_hendlers";

class RecordAPI_wavesurfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      init: false,
      canRecordNew: true,
      loading: false,

      micOptions: [],
      micSelected: null,
      scrollingWaveform: false,
    };

    this.pauseButton = React.createRef();
    this.recButton = React.createRef();

    this.countAudious = 0;

    this.wavesurfer = false;
    this.record = false;

    this.createWaveSurfer = this.createWaveSurfer.bind(this);
    this.updateProgress = this.updateProgress.bind(this);

    // this.onMicOptions = this.onMicOptions.bind(this);
    this.onPauseClick = this.onPauseClick.bind(this);
    this.onRecordClick = this.onRecordClick.bind(this);
    this.onScrollingWaveChange = this.onScrollingWaveChange.bind(this);

    this.submit = this.submit.bind(this);
  }
  updateProgress(time) {
    const progress = document.querySelector("#progress");
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    progress.textContent = formattedTime;
  }

  onPauseClick() {
    if (this.record.isPaused()) {
      this.record.resumeRecording();
      this.pauseButton.current.textContent = "Pause";
      return;
    }

    this.record.pauseRecording();
    this.pauseButton.current.textContent = "Resume";
  }

  //   onMicOptions() {
  //     RecordPlugin.getAvailableAudioDevices().then((devices) => {
  //       let opts = [];
  //       devices.forEach((device) => {
  //         opts.push({
  //           label: device.label || device.deviceI,
  //           value: device.deviceId,
  //         });
  //       });
  //       this.setState({ micOptions: opts });
  //     });
  //   }

  onRecordClick() {
    if (this.record.isRecording() || this.record.isPaused()) {
      this.record.stopRecording();
      this.recButton.current.textContent = "Record";
      this.pauseButton.current.style.display = "none";
      return;
    }

    this.recButton.current.disabled = true;

    // get selected device
    const deviceId = this.state.micSelected;
    this.record.startRecording({ deviceId }).then(() => {
      this.recButton.current.textContent = "Stop";
      this.recButton.current.disabled = false;
      this.pauseButton.current.style.display = "inline";
    });
  }

  onScrollingWaveChange(e) {
    this.setState(
      { scrollingWaveform: e.target.checked },
      this.createWaveSurfer
    );
  }

  submit(blob) {
    console.log("SUBMIT AUDIO", blob);

    const formData = new FormData();
    formData.append("file", blob, "myVoiceExample.mpga");

    if (this.props.isSimpleAnswer) {
      this.props.onAudioSubmit(formData);
      return;
    }

    this.props.onLoader(true);

    voiceCreate(formData)
      .then((r) => {
        console.log(r);
        this.props.onAudioSubmit();
      })
      .catch((e) => {
        console.log(e);
        this.props.onError(`Error on Submit Voice audio`);
        this.props.onLoader(false);
      })
      .finally(() => {});
  }

  componentDidMount() {
    this.createWaveSurfer();
  }

  createWaveSurfer() {
    // Create an instance of WaveSurfer
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    this.wavesurfer = WaveSurfer.create({
      container: "#mic",
      waveColor: "rgb(200, 0, 200)",
      progressColor: "rgb(100, 0, 100)",
    });

    // Initialize the Record plugin
    this.record = this.wavesurfer.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: this.state.scrollingWaveform,
        renderRecordedAudio: false,
      })
    );
    // Render recorded audio
    this.record.on("record-end", (blob) => {
      this.countAudious += 1;
      const container = document.querySelector("#recordings");
      const recordedUrl = URL.createObjectURL(blob);

      if (this.props.isSimpleAnswer) {
        container.innerHTML = "";
      }

      // Create wavesurfer from the recorded audio
      const wavesurfer = WaveSurfer.create({
        container,
        waveColor: "rgb(200, 100, 0)",
        progressColor: "rgb(100, 50, 0)",
        url: recordedUrl,
      });

      // Play button
      const button = container.appendChild(document.createElement("button"));
      button.textContent = this.props.isSimpleAnswer
        ? "Play"
        : `Play ${this.countAudious} example`;
      button.classList.add("btn", "btn-dark", "waves-effect", "waves-light");
      button.onclick = () => wavesurfer.playPause();
      wavesurfer.on(
        "pause",
        () =>
          (button.textContent = this.props.isSimpleAnswer
            ? "Play"
            : `Play ${this.countAudious} example`)
      );
      wavesurfer.on("play", () => (button.textContent = "Pause"));

      const buttonSubmit = container.appendChild(
        document.createElement("button")
      );
      buttonSubmit.textContent = this.props.isSimpleAnswer
        ? "Submit"
        : `Submit ${this.countAudious} example`;
      buttonSubmit.style.marginLeft = "20px";
      buttonSubmit.classList.add(
        "btn",
        "btn-primary",
        "waves-effect",
        "waves-light"
      );
      buttonSubmit.onclick = () => this.submit(blob);
    });
    this.pauseButton.current.style.display = "none";
    this.recButton.current.textContent = "Record";

    this.record.on("record-progress", (time) => {
      this.updateProgress(time);
    });
  }

  render() {
    if (this.props.isSimpleAnswer) {
      return (
        <>
          <p className="mb-1" style={{ fontSize: 10 }}>
            Press "RECORD" 🎙️ <span id="progress">00:00</span>
          </p>

          <div className="row">
            <Col>
              <button
                id="record"
                ref={this.recButton}
                color="primary"
                className="btn btn-danger waves-effect waves-light"
                onClick={this.onRecordClick}
              >
                <i className="bx bx-stop font-size-16 align-middle me-1"></i>
                Record
              </button>
              <button
                color="primary"
                id="pause"
                ref={this.pauseButton}
                className="btn btn-dark waves-effect waves-light"
                onClick={this.onPauseClick}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-1"></i>
                Pause
              </button>
            </Col>

            <Col>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="business_regions_of_operation"
                  checked={this.state.scrollingWaveform}
                  id={"Scrolling"}
                  onChange={this.onScrollingWaveChange}
                />
                <label className="form-check-label" htmlFor={"Scrolling"}>
                  Scrolling waveform
                </label>
              </div>

              {/* <label style={{ display: "inline-block" }}>
              <input type="checkbox" /> Scrolling waveform
            </label> */}
            </Col>
          </div>

          <div
            id="mic"
            style={{
              border: "1px solid #ddd",
              borderRadius: "4px",
              marginTop: "1rem",
            }}
          ></div>

          <div id="recordings" style={{ margin: "1rem 0" }}></div>
        </>
      );
    }

    return (
      <Card
        className={`wrapper${this.props.isOpenRecordModal ? "" : " hidden"}`}
      >
        <CardBody>
          <p className="text-muted">{TEXT_EXAMPLE_READ}</p>

          <p className="mb-1" style={{ fontSize: 10 }}>
            Press "RECORD" and read text above 🎙️{" "}
            <span id="progress">00:00</span>
          </p>

          <div className="row">
            <Col>
              <button
                id="record"
                ref={this.recButton}
                color="primary"
                className="btn btn-danger waves-effect waves-light"
                onClick={this.onRecordClick}
              >
                <i className="bx bx-stop font-size-16 align-middle me-1"></i>
                Record
              </button>
              <button
                color="primary"
                id="pause"
                ref={this.pauseButton}
                className="btn btn-dark waves-effect waves-light"
                onClick={this.onPauseClick}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-1"></i>
                Pause
              </button>
            </Col>

            <Col>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="business_regions_of_operation"
                  checked={this.state.scrollingWaveform}
                  id={"Scrolling"}
                  onChange={this.onScrollingWaveChange}
                />
                <label className="form-check-label" htmlFor={"Scrolling"}>
                  Scrolling waveform
                </label>
              </div>

              {/* <label style={{ display: "inline-block" }}>
              <input type="checkbox" /> Scrolling waveform
            </label> */}
            </Col>
          </div>

          <div
            id="mic"
            style={{
              border: "1px solid #ddd",
              borderRadius: "4px",
              marginTop: "1rem",
            }}
          ></div>

          <div id="recordings" style={{ margin: "1rem 0" }}></div>
        </CardBody>
      </Card>
    );
  }
}

export default RecordAPI_wavesurfer;
