import React, { useEffect, useState, useMemo } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// import { connect } from "react-redux";
import { Link } from "react-router-dom";

import withRouter from "components/Common/withRouter";

import {
  Campaing,
  Delivered,
  LastUpdated,
  ClickRate,
  CVR,
  Status,
  Launched,
  Unsubs,
  Revenue,
} from "./campaingsColumns";

import TableContainer from "../../components/Common/TableContainer";

import logoImg from "../../assets/images/logo.svg";

import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getCampaingsAction, deleteCampaingAction } from "store/actions";
import Breadcrumb from "components/Common/Breadcrumb";

const Capmaings = (props) => {
  const { router } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  useEffect(() => {
    dispatch(getCampaingsAction());
  }, []);

  const { list } = useSelector(
    (state) => ({
      list: state.campaings.list,
    }),
    shallowEqual
  );

  const handleDeleteFlow = (id) => {
    dispatch(deleteCampaingAction(id));
  };

  const handleSmartSending = () => {
    console.log("CLICK SMART");
  };

  const columns = useMemo(
    () => [
      {
        Header: "Campaign",
        // accessor: "title",
        // canFilter: false,
        // filterable: false,
        Cell: (cellProps) => {
          return (
            <Campaing
              cellProps={cellProps}
              handleSmartSending={handleSmartSending}
            />
          );
        },
      },
      {
        Header: "Status",
        // accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      // {
      //   Header: "Delivered",
      //   // accessor: "modified",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Delivered {...cellProps} />;
      //   },
      // },

      {
        Header: "Launched",
        // accessor: "modified",
        filterable: true,
        Cell: (cellProps) => {
          return <Launched {...cellProps} />;
        },
      },
      // {
      //   Header: "CVR",
      //   // accessor: "modified",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <CVR {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "Unsubs",
      //   // accessor: "modified",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Unsubs {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "Revenue",
      //   // accessor: "modified",
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Revenue {...cellProps} />;
      //   },
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          const flowData = cellProps.row.original;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/campaings/edit/${flowData.id}`}
                state={flowData}
                className="text-success"
                onClick={() => {}}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteFlow(flowData.id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleCreateFlow = () => {
    router.navigate(`/campaings/create`);
  };

  return (
    <React.Fragment>
      <div className="page-content pageContent">
        <Container fluid>
        <Breadcrumb title="Campaigns" breadcrumbItems={props?.router?.location?.pathname}/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    bordered={false}
                    columns={columns}
                    data={list}
                    isGlobalFilter={true}
                    isAddOptions={"Create campaign"}
                    customPageSize={10}
                    handleOrderClicks={handleCreateFlow}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Capmaings);
