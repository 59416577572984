export const UPDATE_STATE_FLOW = "UPDATE_STATE_FLOW";

export const GET_FLOWS = "GET_FLOWS";
export const GET_FLOWS_SUCCESS = "GET_FLOWS_SUCCESS";
export const CREATE_FLOW = "CREATE_FLOW";
export const CREATE_FLOW_SUCCESS = "CREATE_FLOW_SUCCESS";
export const EDIT_FLOW = "EDIT_FLOW";
export const EDIT_FLOW_SUCCESS = "EDIT_FLOW_SUCCESS";
export const GET_FLOW_BY_ID = "GET_FLOW_BY_ID";
export const GET_FLOW_BY_ID_SUCCESS = "GET_FLOW_BY_ID_SUCCESS";
export const DELETE_FLOW = "DELETE_FLOW";
export const DELETE_FLOW_SUCCESS = "DELETE_FLOW_SUCCESS";

export const API_ERROR_FLOWS = "API_ERROR_FLOWS";

export const EDIT_FLOW_CONFIGURE = "EDIT_FLOW_CONFIGURE";
