import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Button } from "reactstrap";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import withRouter from "components/Common/withRouter";

import { getMyAIdata, changeOne } from "store/actions";

import { INIT_MY_AI_DATA } from "config";

import { optionsChart } from "helpers/chartHelper";

const Root = ({ setActiveScreen, activeScreen }) => {
  const dispatch = useDispatch();
  const myRef = useRef();
  const timerRef = useRef();

  // const { my_ai_data } = useSelector(
  //   (state) => ({
  //     my_ai_data: state.my_ai.data,
  //   }),
  //   shallowEqual
  // );

  const [state, setState] = useState({
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 0,
        },
      ],
      labels: [],
    },
  });

  useEffect(() => {
    setState((pS) => {
      const newDat = { ...pS };
      newDat.data.datasets[0].data = [];
      newDat.data.datasets[0].backgroundColor = [];
      newDat.data.labels = [];

      INIT_MY_AI_DATA.forEach((element) => {
        newDat.data.datasets[0].data.push(element.value);
        newDat.data.datasets[0].backgroundColor.push(element.color);
        newDat.data.labels.push(element.label);
      });
      return newDat;
    });

    timerRef.current = setInterval(periodicCheckData, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const periodicCheckData = () => {
    console.log("periodicCheckData")
    if (myRef.current) {
      const updateConfig = {
        duration: 1000, // Animation duration for the update
        easing: "easeInOutQuad", // Easing function for the animation
      };

      let data = myRef.current.data.datasets[0].data;
      let isFull = [];

      for (let i = 0; i < data.length; i++) {
        const value = data[i];
        if (value < 100) {
          myRef.current.data.datasets[0].data[i] =
            myRef.current.data.datasets[0].data[i] + 25;
          dispatch(changeOne(i, value + 25));
          break;
        }

        if (value >= 100) {
          isFull.push({ i, value });
        }
      }

      if (isFull.length === data.length) {
        clearInterval(timerRef.current);
        return;
      }
      myRef.current.update({ duration: 0 });
      myRef.current.update(updateConfig);

      myRef.current.update({
        duration: myRef.current.options.animation.duration,
      });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <PolarArea
            ref={myRef}
            width={800}
            height={300}
            data={state.data}
            options={optionsChart()}
            redraw={true}
            updateMode="resize"
            plugins={[ChartDataLabels]}
          />
        </CardBody>
      </Card>

      <Card>
        <div className="table-responsive">
          <table className="table table-nowrap align-middle mb-0">
            <tbody>
              {INIT_MY_AI_DATA.map((step) => (
                <tr key={step.label}>
                  <td style={{ backgroundColor: "#fff" }}>
                    <h5 className="text-truncate font-size-16 m-0">
                      {step.label}
                    </h5>
                  </td>
                  <td style={{ backgroundColor: "#fff" }}>
                    <h5 className="text-truncate font-size-18 m-0">
                      {step.value}%
                    </h5>
                  </td>

                  <td
                    style={{
                      backgroundColor: "#fff",
                      float: "inline-end",
                    }}
                  >
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded bgcp-4"
                      onClick={() => {
                        setActiveScreen(step.label);
                      }}
                    >
                      Continue
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(Root);
