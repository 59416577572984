import {
  UPDATE_STATE,
  CLEAR_CHAT,
  REQUEST_VOICE_MAILS,
  REQUEST_VOICE_MAILS_SUCCESS,
  UPDATE_VOICE_MAILS_STATUS,
  UPDATE_VOICE_MAILS_STATUS_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const fetchVoiceMails = (userId) => ({
  type: REQUEST_VOICE_MAILS,
  payload: userId,
});

export const fetchVoiceMailsSuccess = (userId) => ({
  type: REQUEST_VOICE_MAILS_SUCCESS,
  payload: userId,
});

export const updateVoiceMailStatus = (userId, recordingSid) => ({
  type: UPDATE_VOICE_MAILS_STATUS,
  payload: { userId, recordingSid },
});

export const updateVoiceMailStatus_Success = (
  recordSID,
  recording_listened
) => ({
  type: UPDATE_VOICE_MAILS_STATUS_SUCCESS,
  payload: { recordSID, recording_listened },
});

export const updateState = (data) => ({
  type: UPDATE_STATE,
  payload: data,
});

export const clearChat = () => ({
  type: CLEAR_CHAT,
  payload: {},
});

export const apiErrorChat = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
