import {
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESSFULL,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  MODIFY_CURRENT_USER,
  MODIFY_CURRENT_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  user: {}
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CONFIRM_PASSWORD_SUCCESSFULL:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;

    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;

    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        error: null,
        ...action.payload,
      };
      break;
    case MODIFY_CURRENT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MODIFY_CURRENT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
      break;

    case LOGOUT_USER:
      state = { ...state };
      break;

    case LOGOUT_USER_SUCCESS:
      state = { ...initialState };
      break;

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
