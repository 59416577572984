import React, { useEffect, useState } from "react";

import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { constructorEmail, createVcard } from "helpers/utility";

export default function ModalSendEmail({
  isOpen,
  onToggle,
  customerEmail,
  user,
  toggleCreateOrder,
  orderLink,
  subject,
  threadId
}) {
  const [state, setState] = useState({
    isExistCompanyProfile: false,
    company: {},
    loading: false,
    showAlert: {
      isShow: false,
      title: "Updated successfully",
      message: "",
      type: "success",
      icon: "mdi-information-outline",
    },
  });

  useEffect(() => {
    if (orderLink) {
      form.setFieldValue("text", `${form.values.text}  ${orderLink}`);
    }

    if (customerEmail) {
      form.setFieldValue("receivers", customerEmail);
    }
  }, [orderLink, customerEmail]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      receivers: customerEmail,
      subject: subject || "",
      text: "",
      attachments: [],
    },
    validationSchema: Yup.object({
      receivers: Yup.string().required("Required"),
      text: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log("onSubmit message ", values);
      setState((ps) => ({ ...ps, loading: true }));
      sendEmail(values);
    },
  });

  async function sendEmail(values) {
    try {
      const email = await constructorEmail({
        to: values.receivers,
        from: user?.email,
        subject: values.subject,
        text: values.text,
        attachments: values.attachments,
      });

      GoogleAPI.sendMessage(email, threadId)
        .then(function (response) {
          console.log("Email sent!", response);
          setState((ps) => ({
            ...ps,
            loading: false,
            showAlert: {
              isShow: true,
              title: "Send successfully",
              message: "",
              type: "success",
              icon: "mdi-information-outline",
            },
          }));
        })
        .catch(function (error) {
          console.error("Error sending email:", error);
          setState((ps) => ({
            ...ps,
            loading: false,
            showAlert: {
              isShow: true,
              title: "Send failed",
              message: "",
              type: "error",
              icon: "mdi-information-outline",
            },
          }));
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  const submitToReview = (e) => {
    e.preventDefault();
    form
      .validateForm()
      .then((r) => {
        console.log("VALIDATION SIDs", r);

        if (JSON.stringify(r) !== "{}") {
          let MS_ER = "";
          for (const key in r) {
            MS_ER += `${key}: "${r[key]}",  `;
          }
          setState((pS) => ({
            ...pS,
            showAlert: {
              isShow: true,
              title: "Some fields are not valide",
              message: MS_ER,
              type: "danger",
              icon: "mdi-block-helper",
            },
          }));
        } else {
          setState(
            (pS) => ({
              ...pS,
              showAlert: {
                isShow: true,
                title: "Sending...",
                message: "",
                type: "success",
                icon: "mdi-information-outline",
              },
            }),
            form.handleSubmit()
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addVcard = async () => {
    const { file_vcard, vcard_type, file_name } = await createVcard(
      user,
      user.image
    );
    form.setFieldValue("attachments", [
      ...form.values.attachments,
      {
        media: file_vcard,
        selectedFile: { type: vcard_type, name: file_name },
      },
    ]);
  };
  const addMedia = (e) => {
    e.preventDefault();
    try {
      const selectedFile = e.target.files[0];
      const fileSize = selectedFile.size;

      if (fileSize > 5000000) {
        this.setState({ errors: "File size can't be more than 5MB" });
        return;
      }

      const reader = new FileReader();

      if (selectedFile.type === "text/plain") {
        reader.readAsText(selectedFile);
      } else {
        reader.readAsArrayBuffer(selectedFile);
      }

      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });

        form.setFieldValue("attachments", [
          ...form.values.attachments,
          { media: blob, selectedFile: selectedFile },
        ]);
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const removeAttachment = (fileName) => {
    const newArrAttachments = form.values.attachments.filter(
      ({ selectedFile }) => selectedFile.name !== fileName
    );
    form.setFieldValue("attachments", newArrAttachments);
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        <p>New message</p>
        {state.showAlert.isShow && (
          <Alert
            style={{ zIndex: 1000, display: "flex", position: "fixed" }}
            isOpen={state.showAlert.isShow}
            color={state.showAlert.type}
            className="alert-dismissible fade show"
            role="alert"
            toggle={() => {
              setState((pS) => ({
                ...pS,
                showAlert: {
                  ...state.showAlert,
                  isShow: !state.showAlert.isShow,
                },
              }));
            }}
          >
            <i className={`mdi ${state.showAlert.icon} me-2 font-size-20`}></i>
            <div>
              <h5>{state.showAlert.title}</h5>{" "}
              {state.showAlert.message && (
                <p className="mb-0">{state.showAlert.message}</p>
              )}
            </div>
          </Alert>
        )}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitToReview}>
          <div className="mb-3 form-group position-relative">
            <Label className="form-label">To:</Label>
            <Input
              name="receivers"
              className="form-control"
              placeholder="receivers..."
              type="text"
              disabled
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.receivers || ""}
              invalid={
                form.touched.receivers && form.errors.receivers ? true : false
              }
            />
            {form.touched.brand_sid && form.errors.brand_sid ? (
              <FormFeedback type="invalid">
                {form.errors.brand_sid}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3 form-group position-relative">
            <Label className="form-label">Subject</Label>
            <Input
              name="subject"
              className="form-control"
              placeholder="Enter subject..."
              type="text"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.subject || ""}
            />
          </div>
          <div className="mb-3 form-group position-relative">
            <Label className="form-label">Message Content</Label>
            <Input
              name="text"
              className="form-control"
              placeholder="Enter message..."
              type="textarea"
              rows={3}
              style={{
                overflow: "auto",
                resize: "none",
                maxHeight: "200px",
              }}
              maxLength={250}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.text || ""}
              invalid={form.touched.text && form.errors.text ? true : false}
            />
            {form.touched.text && form.errors.text ? (
              <FormFeedback type="invalid">{form.errors.text}</FormFeedback>
            ) : null}
          </div>

          {form.values.attachments && (
            <div className="mb-3 form-group position-relative">
              <Label className="form-label">Attachments</Label>
              <ul className="attachments_list">
                {form.values.attachments.map((att, i) => (
                  <li key={i}>
                    <div className="overlay">
                      <a
                        href="#"
                        onClick={() => removeAttachment(att.selectedFile.name)}
                      >
                        Delete
                      </a>
                    </div>

                    {att.selectedFile.type.startsWith("image/") ? (
                      <img
                        src={att.media ? URL.createObjectURL(att.media) : null}
                        alt={att.selectedFile.name}
                      />
                    ) : (
                      <p>{att.selectedFile.name}</p>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <Row className="align-items-center">
            <Col className="col-auto">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary btn-rounded chat-send w-md "
              >
                <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
                <i className="mdi mdi-send" />
              </Button>
            </Col>
            <Col>
              <div className="position-relative">
                <div className="chat-input-links">
                  <ul className="list-inline mb-0 d-flex align-items-center">
                    <li className="list-inline-item">
                      <i
                        className="bx bxs-spreadsheet me-1 pt-1 cp-2 font-size-26"
                        id="createOrder"
                        onClick={() => toggleCreateOrder("email")}
                      />
                      <UncontrolledTooltip placement="top" target="createOrder">
                        Create Order
                      </UncontrolledTooltip>
                    </li>

                    <li className="list-inline-item">
                      <i
                        className="mdi mdi-card-account-phone me-1 cp-2 font-size-22"
                        id="vcard"
                        onClick={addVcard}
                      />
                      <UncontrolledTooltip placement="top" target="vcard">
                        Send your vCard
                      </UncontrolledTooltip>
                    </li>

                    <li className="list-inline-item position-relative">
                      <label
                        htmlFor="add_file"
                        className="position-absolute top-0"
                        id="Imagetooltip"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      ></label>
                      <i
                        className="mdi mdi-file-plus me-1 cp-2 font-size-22"
                        id="Imagetooltip"
                      />
                      <input
                        type="file"
                        id="add_file"
                        className="file-input"
                        style={{ display: "none" }}
                        onChange={addMedia}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="Imagetooltip"
                      >
                        Add File (max size: 5MB)
                      </UncontrolledTooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
}
