import React from "react";

import shopify_img from "assets/images/shopify.png";

export default function LineItem({
  item,
  onSelectProduct,
  onAmountChange,
  themeColor,
}) {

  return (
    <tr>
      <td onClick={() => onSelectProduct(!item.isSelect)}>
        <div>
          <i
            className={`${
              item.isSelect ? "bx bxs-check-circle" : "bx bx-circle"
            } font-size-35 cp-2`}
            style={themeColor ? { color: themeColor } : {}}
          ></i>
        </div>
      </td>
      <td className="p-1" onClick={() => onSelectProduct(!item.isSelect)}>
        <div>
          <img
            className="avatar-md"
            src={item.image ? item.image : shopify_img}
            alt=""
          />
        </div>
      </td>
      <td className="p-1">
        <h5 className="font-size-16 mb-2 text-truncate text-wrap">{item.title}</h5>
        <h5 className="font-size-12 mb-1">Price: ${item.price}</h5>
        {/* <small className="text-wrap">Description : {item.description}</small> */}
      </td>
      <td className="p-1">
        <div className="amount_input">
          <div
            className="input-group-append"
            onClick={() => {
              if (item.isSelect) {
                onAmountChange(item.quantity + 1);
              }
            }}
          >
            <i
              className={`bx bxs-up-arrow font-size-30 ${
                item.isSelect ? "cp-2" : "text-light"
              }`}
              style={themeColor ? { color: themeColor } : {}}
            />
          </div>

          <h4 className="m-0 text-center">{item.quantity}</h4>

          <div
            className="input-group-append"
            onClick={() => {
              if (item.quantity > 1 && item.isSelect) {
                onAmountChange(item.quantity - 1);
              }
            }}
          >
            <i
              className={`bx bxs-down-arrow font-size-30 ${
                item.isSelect ? "cp-2" : "text-light"
              }`}
              style={themeColor ? { color: themeColor } : {}}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
