import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Alert,
  Progress,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import RecordAPI_wavesurfer from "helpers/waveSurfer_record";

import { changeLoader } from "store/actions";

class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transcribe: "",
      isListen: false,
      answer: { type: "text", value: "" },
      question: { current: 1, text: "How old are you?", total: 100 },
    };

    this.initSpeechAPI = this.initSpeechAPI.bind(this);
    this.onSubmitAnswer = this.onSubmitAnswer.bind(this);
  }

  componentDidMount() {
    this.initSpeechAPI();
  }

  initSpeechAPI() {
    this.recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition)();

    this.recognition.lang = "en-US";
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    // this.recognition.maxAlternatives = 10;

    this.recognition.onstart = () => {
      this.setState({ isListen: true });
    };

    this.recognition.onresult = (event) => {
      // console.log(event);

      // const transcript = event.results[0][0].transcript;
      // console.log("transcript", transcript);

      let fullText = "";
      for (const result of event.results) {
        fullText += result[0].transcript;
      }

      this.setState((ps) => ({
        ...ps,
        answer: { type: "voice", value: fullText },
      }));
    };

    this.recognition.onend = () => {
      this.setState({ isListen: false });
      // this.recognition.stop();
    };

    this.recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    this.recognition.onnomatch = () => {
      console.log("No speech was recognized.");
    };
  }

  onSubmitAnswer() {
    console.log("onSubmitAnswer state", this.state);
    // if (!formData) {
    //   console.log("save as text", state.answer.value);
    // } else {
    //   console.log("save as voice", formData);
    // }

    this.setState((ps) => ({
      ...ps,
      answer: { ...ps.answer, type: "text", value: "" },
      question: { ...ps.question, current: ps.question.current + 1 },
    }));
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Progress
              value={this.state.question.current}
              // animated
              // striped
              color="warning"
              style={{
                height: "40px",
              }}
            >
              <h5 className="text-truncate text-center font-size-18 m-0">{this.state.question.current}%</h5>
            </Progress>

            <h5 className="text-truncate text-center font-size-14 mb-3 mt-3">
              Level 1 - Quesion {this.state.question.current} of{" "}
              {this.state.question.total}
            </h5>

            <p className="m-0 ">{this.state.question.text}</p>

            <FormGroup className="mt-3">
              {/* <Label for="exampleText">Type your answer or record audio</Label> */}
              <Input
                id="exampleText"
                placeholder="Type your answer "
                name="text"
                type="textarea"
                value={this.state.answer.value}
                rows="5"
                onFocus={() => {
                  this.setState((ps) => ({
                    ...ps,
                    answer: { ...ps.answer, type: "text" },
                  }));
                }}
                onChange={({ target }) => {
                  this.setState((ps) => ({
                    ...ps,
                    answer: { ...ps.answer, value: target.value },
                  }));
                }}
              />
            </FormGroup>

            {/* {state.answer.type === "voice" && (
              <RecordAPI_wavesurfer
                onLoader={(load) => dispatch(changeLoader(load))}
                isSimpleAnswer={true}
                onAudioSubmit={(formData) => onSubmitAnswer(formData)}
              />
            )} */}

            {/* {state.answer.type !== "voice" && ( */}
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="mic_cont"
                onClick={() => {
                  if (this.state.isListen) {
                    this.recognition.stop();
                  } else {
                    this.recognition.start();
                  }
                }}
              >
                {this.state.isListen ? (
                  <i className="bx bx-stop-circle bx-scaling font-size-24"></i>
                ) : (
                  <i className="fas fa-microphone font-size-24"></i>
                )}
              </div>
              <Button
                color="primary"
                className="btn btn-primary ms-3"
                onClick={this.onSubmitAnswer}
              >
                Submit
              </Button>
            </div>
            {/* )} */}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withRouter(Personal);
