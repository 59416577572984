import {
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESSFULL,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  MODIFY_CURRENT_USER,
  MODIFY_CURRENT_USER_SUCCESS,
  SOCIAL_LOGIN,
} from "./actionTypes";

export const confirmCreatePassword = (data, navigate) => {
  return {
    type: CONFIRM_PASSWORD,
    payload: { data, navigate },
  };
};

export const confirmCreatePasswordSuccessful = (user) => {
  return {
    type: CONFIRM_PASSWORD_SUCCESSFULL,
    payload: user,
  };
};

export const registerUser = (user, navigate) => {
  return {
    type: REGISTER_USER,
    payload: { user, navigate },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const modifyCurrentUser = (user) => {
  return {
    type: MODIFY_CURRENT_USER,
    payload: user,
  };
};

export const modifyCurrentUserSuccess = (user) => {
  return {
    type: MODIFY_CURRENT_USER_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
