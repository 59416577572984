export const UPDATE_STATE_CAMPAING = "UPDATE_STATE_CAMPAING";

export const GET_CAMPAINGS = "GET_CAMPAINGS";
export const GET_CAMPAINGS_SUCCESS = "GET_CAMPAINGS_SUCCESS";
export const CREATE_CAMPAING = "CREATE_CAMPAING";
export const CREATE_CAMPAING_SUCCESS = "CREATE_CAMPAING_SUCCESS";
export const EDIT_CAMPAING = "EDIT_CAMPAING";
export const EDIT_CAMPAING_SUCCESS = "EDIT_CAMPAING_SUCCESS";
export const GET_CAMPAING_BY_ID = "GET_CAMPAING_BY_ID";
export const GET_CAMPAING_BY_ID_SUCCESS = "GET_CAMPAING_BY_ID_SUCCESS";
export const DELETE_CAMPAING = "DELETE_CAMPAING";
export const DELETE_CAMPAING_SUCCESS = "DELETE_CAMPAING_SUCCESS";

export const API_ERROR_CAMPAINGS = "API_ERROR_CAMPAINGS";

export const EDIT_CAMPAING_CONFIGURE = "EDIT_CAMPAING_CONFIGURE";
