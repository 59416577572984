import React from "react";
import { Button } from "reactstrap";

import withRouter from "components/Common/withRouter";

const SMS = ({ id, onDoneEdit, router, dataForPass }) => {
  console.log("dataForPass", dataForPass);

  return (
    <div className="editBlock" id={id}>
      <div className="contentEdit">
        <h5>Content</h5>

        <p>{dataForPass.settings.title}</p>
        <p>{dataForPass.settings.text}</p>

        <Button
          outline
          onClick={() =>
            router.navigate("/sms/configure", { state: dataForPass })
          }
          style={{ backgroundColor: "#000", color: "#fff", width: "100%" }}
        >
          Configure Content
        </Button>
      </div>

      <div className="settings">
        <h5>Analitics (30 DAYS)</h5>

        <div className="setting_item">
          <p>Delivered</p>
          <p>0</p>
        </div>
        <div className="setting_item">
          <p>Click Rate</p>
          <p>0%</p>
        </div>
        <div className="setting_item">
          <p>PlacedOrder Rate</p>
          <p>0%</p>
        </div>
        <div className="setting_item">
          <p>Revenue </p>
          <p>0.00%</p>
        </div>
      </div>

      <div className="settings">
        <h5>Settings</h5>

        <div className="setting_item">
          <i className="mdi mdi-email-check" id={`sending`}></i>
          <p>Smart Sending</p>
          <p>ON</p>
        </div>
        <div className="setting_item">
          <i className="mdi mdi-go-kart-track" id={`sending`}></i>
          <p>UTM Tracking</p>
          <p>ON</p>
        </div>
        <div className="setting_item">
          <i className="mdi mdi-filter" id={`sending`}></i>
          <p>Additional Filters</p>
          <p>OFF</p>
        </div>
      </div>

      <div className="buttons">
        <Button outline onClick={onDoneEdit}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default withRouter(SMS);
