import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Nav,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { createImageLink, formatBytes } from "helpers/utility";
import withRouter from "components/Common/withRouter";
import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from "react";
import { editFlow } from "helpers/API_hendlers";

const SMS_configure = ({ router }) => {
  const { editFlowActions, flowID } = useSelector(
    (state) => ({
      editFlowActions: state.flow.editFlow.actions,
      flowID: state.flow.editFlow.id,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    activeTab: 1,
    image: { selectedFile: null, value: "", src: "" },
    text: "",
    title: "",
  });

  useEffect(() => {
    // console.log("SMS_configure", router);

    setState((pS) => ({
      ...pS,
      text: router?.location?.state?.settings?.text || "",
      title: router?.location?.state?.settings?.title || "",
      image: {
        selectedFile: null,
        value: "",
        src: router?.location?.state?.settings?.imgSrc || "",
      },
    }));
  }, []);

  const phone = "+1(XXX)-XXX-XX-XX";

  let preview = { src: "", name: "", size: 0 };
  if (state.image.selectedFile) {
    preview.src = URL.createObjectURL(state.image.selectedFile);
    preview.name = state.image.selectedFile.name;
    preview.size = formatBytes(state.image.selectedFile.size);
  }

  const onSaveConfigure = async () => {
    console.log("onSaveConfigure", editFlowActions);
    const newData = {
      text: state.text,
      imgSrc: "",
    };

    const image = await createImageLink(state.image.selectedFile);
    if (image && image.link) {
      newData.imgSrc = image.link;
    }
    const actID = router?.location?.state?.id;

    const updatedActions = editFlowActions.map((act) => {
      if (act.id === actID) {
        return {
          ...act,
          settings: { ...act.settings, ...newData },
        };
      }
      return act;
    });

    console.log("onSaveConfigure", updatedActions);

    editFlow(flowID, {
      actions: updatedActions,
    })
      .then((r) => {
        console.log(r);
        router.navigate(-1);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="page-content" style={{ padding: "70px 0 0 0" }}>
      <Row className="flow_container">
        <Col xs={4} className="flow_leftBlock mainScreen">
          <div>
            <h2>{state.title}</h2>
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={state.activeTab === 1}
                  onClick={() => {
                    setState({ ...state, activeTab: 1 });
                  }}
                >
                  Content
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={state.activeTab === 2}
                  onClick={() => {
                    setState({ ...state, activeTab: 2 });
                  }}
                >
                  Compliance
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId={1}>
                <h3 style={{ marginTop: 20 }}>Message Content</h3>
                <div>
                  <FormGroup>
                    <Input
                      id="exampleText"
                      name="text"
                      type="textarea"
                      value={state.text}
                      onChange={(e) => {
                        setState({ ...state, text: e.target.value });
                      }}
                    />
                    <Label for="exampleFile">Add photo</Label>
                    <Input
                      id="exampleFile"
                      name="image"
                      type="file"
                      onChange={(e) => {
                        const selectedImage = e.target.files[0];                   
                        setState((pS) => ({
                          ...pS,
                          image: {
                            ...pS.image,
                            value: e.target.value,
                            selectedFile: selectedImage,
                          },
                        }));
                      }}
                      value={state.image.value}
                    />
                  </FormGroup>
                  <div className="form-group">
                    {state.image?.selectedFile && (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="150"
                                alt={preview.name}
                                src={preview.src}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {preview.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{preview.size}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <h3>Compliance Content</h3>
              </TabPane>
            </TabContent>
          </div>

          <Button outline onClick={onSaveConfigure}>
            Save Changes
          </Button>
        </Col>
        <Col className="flow_rightBlock">
          <div className="phone_template">
            <header>
              <p>{phone}</p>
            </header>
            <main>
              {state.image?.selectedFile && (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="150"
                          alt={preview.name}
                          src={preview.src}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}

              {!state.image.selectedFile && !!state.image.src && (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="150"
                          alt={state.image.src}
                          src={state.image.src}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}

              <div className="chat-conversation p-3">
                <div className="conversation-list">
                  <div className="ctext-wrap">
                    <div className="conversation-name">Text</div>

                    <p
                      style={{
                        textAlign: "left",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {state.text}
                    </p>
                  </div>
                </div>
              </div>
            </main>
            <footer>
              <div />
              <div />
              <div />
            </footer>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(SMS_configure);
