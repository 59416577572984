import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledAlert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap";
import { map } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumb from "components/Common/Breadcrumb";
import PaymentItem from "./payment-item";
import { getCurrentUser, modifyCurrentUser } from "helpers/API_hendlers";

const SETTINGS = [
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg",
    name: "Stripe",
    public_key: "",
    secret_key: "",
  },
  // {
  //   img: "https://upload.wikimedia.org/wikipedia/commons/b/b7/PayPal_Logo_Icon_2014.svg",
  //   name: "PayPal",
  //   api_key: "",
  // },
  // {
  //   img: "https://1000logos.net/wp-content/uploads/2023/03/Google-Pay-logo.png",
  //   name: "Google Pay",
  //   api_key: "",
  // },
  // {
  //   img: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Apple_Pay_logo.svg",
  //   name: "Apple Pay",
  //   api_key: "",
  // },
];

const PaymentSettings = (props) => {
  const [setting, setSetting] = useState(SETTINGS[0]);
  const [loading, setLoading] = useState();

  const handleClick = (settingName) => {
    // console.log(settingName);
    setSetting(SETTINGS.find(({ name }) => name === settingName));
  };

  useEffect(() => {
    getCurrentUser()
      .then((r) => {
        console.log(r);
        if (r.user) {
          const { stripe_public_key, stripe_secret_key } = r.user;
          validation.setValues({
            public_key: stripe_public_key,
            secret_key: stripe_secret_key,
          });
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      public_key: "",
      secret_key: "",
    },
    onSubmit: async (values) => {
      console.log("values", values);

      modifyCurrentUser({stripe_public_key: values.public_key, stripe_secret_key: values.secret_key})
        .then((r) => {
          console.log(r);         
        })
        .catch((e) => console.log(e));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid>
          <Breadcrumb
            title="Payment Settings"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          <Row>
            {map(SETTINGS, (settingItem, key) => (
              <PaymentItem
                setting={settingItem}
                key={"_user_" + key}
                handleClick={handleClick}
                isActive={setting.name === settingItem.name}
              />
            ))}
          </Row>
          {setting && (
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="form-group mb-3">
                    <Label className="form-label">
                      {setting.name} Public Key
                    </Label>
                    <Input
                      name="public_key"
                      className="form-control"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.public_key || ""}
                      invalid={
                        validation.touched.public_key &&
                        validation.errors.public_key
                          ? true
                          : false
                      }
                    />
                    {validation.touched.public_key &&
                    validation.errors.public_key ? (
                      <FormFeedback type="invalid">
                        {validation.errors.public_key}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-group mb-3">
                    <Label className="form-label">
                      {setting.name} Secret Key
                    </Label>
                    <Input
                      name="secret_key"
                      className="form-control"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.secret_key || ""}
                      invalid={
                        validation.touched.secret_key &&
                        validation.errors.secret_key
                          ? true
                          : false
                      }
                    />
                    {validation.touched.secret_key &&
                    validation.errors.secret_key ? (
                      <FormFeedback type="invalid">
                        {validation.errors.secret_key}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="text-center mt-4">
                    {loading ? (
                      <Spinner className="ms-2" color="light" />
                    ) : (
                      <Button type="submit" color="primary">
                        Save
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PaymentSettings);
