import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
} from "reactstrap";

import withRouter from "components/Common/withRouter";

import { getMyAIdata, changeOne } from "store/actions";

import { INIT_MY_AI_DATA } from "config";

import Root from "./components/Root";
import VoiceTraining from "./components/VoiceTraining";
import Personal from "./components/Personal";
import Pricing from "./components/Pricing";
import Onboarding from "./components/Onboarding";
import ProductInfo from "./components/ProductInfo";
import CompanyInfo from "./components/CompanyInfo";
import Recovers from "./components/Recovers";
import CustomSupport from "./components/CustomSupport";

const MyAI = (props) => {
  // const { my_ai_data } = useSelector(
  //   (state) => ({
  //     my_ai_data: state.my_ai.data,
  //   }),
  //   shallowEqual
  // );

  const [isListen, setListen] = useState(true);
  const [activeScreen, setActiveScreen] = useState("root");
  const [error, setError] = useState(null);

  return (
    <React.Fragment>
      <div className="page-content pageContent">
        <Container fluid={true}>
          <div className="d-flex bd-highlight align-items-center">
            <div className="p-2 flex-grow-1 bd-highlight">
              <h4 className="mb-0 font-size-20">MY AI</h4>
            </div>
            <div className="p-2 bd-highlight">
              <h4 className="mb-0 font-size-18">
                Status: {isListen ? "listening" : "offline"}
              </h4>
            </div>
            <div className="p-2 bd-highlight">
              <div
                className={`listingBtn ${isListen ? "isListen" : ""}`}
                onClick={() => setListen(!isListen)}
              >
                {isListen ? "ON" : "OFF"}
              </div>
            </div>
          </div>

          <Breadcrumb>
            <BreadcrumbItem
              active={activeScreen === "root"}
              onClick={() => setActiveScreen("root")}
              className="bread_cust"
            >
              Dashboard
            </BreadcrumbItem>
            {activeScreen !== "root" && (
              <BreadcrumbItem
                active={activeScreen !== "root"}
                className="bread_cust"
              >
                {activeScreen}
              </BreadcrumbItem>
            )}
          </Breadcrumb>

          {error && (
            <Alert
              style={{
                zIndex: 1000,
                display: "flex",
                position: "fixed",
                alignItems: "center",
              }}
              isOpen={error.isOpen}
              color={"warning"}
              className="alert-dismissible fade show"
              role="alert"
              toggle={() => {
                setError(null);
              }}
            >
              <i className={`mdi mdi-block-helper me-2 font-size-20`}></i>
              <div>
                <p className="mb-0">{error.message}</p>
              </div>
            </Alert>
          )}

          <Row>
            {activeScreen === "root" && (
              <Root
                setActiveScreen={setActiveScreen}
                activeScreen={activeScreen}
              />
            )}
            {activeScreen === "Personal" && <Personal />}
            {activeScreen === "Pricing" && <Pricing />}
            {activeScreen === "On boarding" && <Onboarding />}
            {activeScreen === "Product Info" && <ProductInfo />}
            {activeScreen === "Company Info" && <CompanyInfo />}
            {activeScreen === "Recovers" && <Recovers />}
            {activeScreen === "Custom Support" && <CustomSupport />}
            {activeScreen === "Voice Training" && (
              <VoiceTraining setError={setError} />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MyAI);
