import React, { useState, useEffect, useRef, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { map } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Card,
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

// import MicrosoftAuth from "components/Common/MicrosoftAuth";

import { base64ToBlob } from "helpers/utility";
import ModalSendEmail from "./ModalSendEmail";

const delay = 300000;

export default function BlockEmail(props) {
  const { toggleCreateOrder, isMobMode, isMobModeChange, customer, orderLink } =
    props;

  const intervals = useRef();
  const [messages, setMessages] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [messageDetails, setMessageDetails] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalThread, setModalThread] = useState(null);
  const [error, setError] = useState({ isShow: false, text: "" });
  const [loading, setLoading] = useState(false);

  const { chat, user } = useSelector(
    (state) => ({
      chat: state.chat,
      user: state.Login.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    const isLogged = GoogleAPI.isLogged();
    if (isLogged) {
      setIsAuthenticated(isLogged);
      intervals.current && clearInterval(intervals.current);
      getGoogleMessages(customer);
      intervals.current = setInterval(getGoogleMessages, delay, customer);
    } else {
      setIsAuthenticated(false);
    }
    return clearIntervals;
  }, []);

  useEffect(() => {
    if (customer?.email) {
      intervals.current && clearInterval(intervals.current);
      getGoogleMessages(customer);
      intervals.current = setInterval(getGoogleMessages, delay, customer);
    } else {
      setMessages([]);
    }
    return clearIntervals;
  }, [customer]);

  const getGoogleMessages = (c) => {
    setLoading(true);
    GoogleAPI.isLogged() &&
      GoogleAPI.getMessages(c)
        .then((ms) => ms && setMessages(ms))
        .catch((e) =>
          setError({
            isShow: true,
            text: e.message || "Error from GoogleAPI" + "please try to relogin",
          })
        )
        .finally(() => setLoading(false));
  };
  const clearIntervals = () => clearInterval(intervals.current);
  const onToggle = () => setMessageDetails(null);
  const onToggleThread = () => setModalThread(null);
  const openContent = useCallback(
    (messageId) => {
      const details = (
        !!modalThread ? modalThread.messageDetails : messages
      ).find(({ id }) => id === messageId);
      if (details) {
        setMessageDetails(details);
      }
    },
    [messages, modalThread]
  );
  const openThread = useCallback((threadId) => {
    if (GoogleAPI.isLogged()) {
      setLoading(true);
      GoogleAPI.getMessagesFromThread(threadId)
        .then((messages) => {
          if (messages) {
            setModalThread(messages);
          }
        })
        .catch((e) =>
          setError({
            isShow: true,
            text: e.message || "Error from GoogleAPI" + "please try to relogin",
          })
        )
        .finally(() => setLoading(false));
    }
  }, []);
  const replyTo = () => {
    setMessageDetails(null);
    setModalMessage(true);
  };
  const handleAuthClick = () => {
    GoogleAPI.handleAuthClick().then((r) => {
      if (r) {
        setIsAuthenticated(r);
        intervals.current && clearInterval(intervals.current);
        getGoogleMessages(customer);
        intervals.current = setInterval(getGoogleMessages, delay, customer);
      }
    });
  };
  const handleSignOut = () => {
    if (GoogleAPI.handleSignoutClick()) {
      setMessages([]);
      setIsAuthenticated(false);
      setMessageDetails(null);
      setModalMessage(false);
      setError({ isShow: false, text: "" });
    }
  };

  const attachments = messageDetails?.parsedBody
    ? messageDetails?.parsedBody.filter((part) => part.attachment)
    : [];

  let dataMD = null;
  if (messageDetails) {
    for (let i = 0; i < messageDetails?.parsedBody.length; i++) {
      const parsedB = messageDetails?.parsedBody[i];
      if (parsedB.data) {
        if (parsedB.mimeType === "text/html") {
          dataMD = { data: parsedB.data, html: true };
          break;
        } else {
          dataMD = { data: parsedB.data };
        }
      }
    }
  }

  return (
    <>
      <div className="w-100 user-chat">
        <div className="p-3 border-bottom ">
          <Row>
            <Col md="5" xs="5" className="d-flex flex-row align-items-center">
              {isMobMode.is && (
                <div style={{ marginRight: 20 }} onClick={isMobModeChange}>
                  <i
                    style={{ fontSize: 30 }}
                    className="mdi mdi-keyboard-backspace me-1"
                    id="vcard"
                  />
                </div>
              )}
              <div className="d-flex align-items-center">
                <p className="font-size-15 m-0 me-2">
                  {chat.conversationProxy &&
                    chat.conversationProxy.friendlyName}{" "}
                  <br />
                  <span className="font-size-10 m-0 me-2">
                    {customer?.email}
                  </span>
                </p>

                {/* <i
                  id={`${chat.conversationProxy?.sid}_pin`}
                  className={`bx ${
                    isPinned ? "bxs-pin" : "bx-pin"
                  } font-size-20 `}
                  onClick={() =>
                    handlePinConversation(
                      chat.conversationProxy?.sid,
                      !isPinned
                    )
                  }
                /> */}
              </div>
            </Col>
            <Col></Col>
            <Col
              md="4"
              xs="2"
              className={`${!isAuthenticated ? "hidden" : ""}`}
            >
              <Button
                type="button"
                color="primary"
                onClick={() => setModalMessage(true)}
                className="btn btn-primary btn-rounded chat-send w-md "
              >
                <i className="mdi mdi-grease-pencil" />
                <span className="d-none d-sm-inline-block ms-2">
                  New message
                </span>{" "}
              </Button>
            </Col>
          </Row>
          {isAuthenticated && (
            <p className="m-0 mt-2">
              Logged as: {isAuthenticated.emailAddress}
            </p>
          )}
        </div>

        <Alert
          isOpen={error.isShow}
          color="warning"
          role="alert"
          className="alert-dismissible fade show"
          toggle={() => setError({ text: "", isShow: false })}
        >
          {error.text}
        </Alert>

        <Card
          className={`position-relative ${
            !!isAuthenticated ? "hidden" : ""
          } d-flex align-items-center justify-content-center`}
          style={{
            height: `calc(100vh - ${isMobMode.is ? "470px" : "350px"})`,
          }}
        >
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary "
              onClick={handleAuthClick}
            >
              <i className="bx bxl-google font-size-16 align-middle me-2"></i>
              Login with Google
            </button>
          </div>

          {/* <MicrosoftAuth
            onLoginSuccess={handleLoginSuccess}
            customer={customer}
            onError={onErrorHandler}
          /> */}
        </Card>

        <Card className={`mb-1 ${!isAuthenticated ? "hidden" : ""}`}>
          <div className="chat-conversation p-3">
            {!messages.length && (
              <p className="text-center">
                Don't have any messages from this user
              </p>
            )}

            <ul className="list-unstyled">
              <PerfectScrollbar
                style={{
                  height: `calc(100vh - ${isMobMode.is ? "470" : "400"}px)`,
                  maxWidth: "100%",
                }}
              >
                {loading && <Spinner />}
                {messages.length > 0 &&
                  map(messages, (message) => (
                    <ItemMessage
                      item={message}
                      key={message.id}
                      openContent={openContent}
                      openThread={openThread}
                    />
                  ))}
              </PerfectScrollbar>
            </ul>
            <Button onClick={handleSignOut} color="primary" className="w-100">
              Sign Out
            </Button>
          </div>
        </Card>
      </div>

      <Modal
        isOpen={!!messageDetails}
        toggle={onToggle}
        className="mess_detail_modal"
      >
        <ModalHeader toggle={onToggle}>
          <p className="text-break">
            {messageDetails?.parsedBody[0]?.headers.Subject ||
              messageDetails?.parsedBody[0]?.headers.subject}
          </p>
          <p>
            {messageDetails?.parsedBody[0]?.headers.From ||
              messageDetails?.parsedBody[0]?.headers.from}
          </p>
        </ModalHeader>
        <ModalBody>
          {dataMD && (
            <>
              {dataMD.html ? (
                <iframe srcDoc={dataMD.data} className="frameMessage"></iframe>
              ) : (
                <p className="text-break">{dataMD.data}</p>
              )}
            </>
          )}

          {!!attachments.length && (
            <ul className="attachments_list">
              {attachments.map((att, i) => (
                <Attachment
                  key={i}
                  filename={att.attachment.filename}
                  base64Data={att.attachment.attachment.result.data}
                  mimeType={att.mimeType}
                />
              ))}
            </ul>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex align-items-center w-100">
            <p className="m-0 me-2 text-truncate">
              {messageDetails?.parsedBody[0]?.headers["Reply-To"] ||
                messageDetails?.parsedBody[0]?.headers["Return-Path"] ||
                messageDetails?.parsedBody[0]?.headers["to"]}
            </p>
            <button
              type="button"
              className="btn btn-success p-1"
              onClick={() =>
                replyTo(messageDetails?.parsedBody[0]?.headers["Reply-To"])
              }
            >
              Reply To
            </button>
            <button onClick={onToggle} className="btn btn-error save-user ms-1">
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={!!modalThread}
        toggle={onToggleThread}
        className="mess_detail_modal"
      >
        <ModalHeader toggle={onToggleThread}>
          <p>
            Thread: "
            {modalThread?.commonThreadData?.parsedBody[0]?.headers.Subject ||
              modalThread?.commonThreadData?.parsedBody[0]?.headers.subject}
            "
          </p>
          <p>
            {modalThread?.commonThreadData?.parsedBody[0]?.headers.From ||
              modalThread?.commonThreadData?.parsedBody[0]?.headers.from}
          </p>
        </ModalHeader>
        <ModalBody>
          {modalThread?.messageDetails &&
            modalThread.messageDetails.length > 0 && (
              <ul className="list-unstyled d-flex">
                <PerfectScrollbar
                  style={{
                    height: `calc(100vh - ${isMobMode.is ? "470px" : "400px"})`,
                  }}
                >
                  {loading && <Spinner />}
                  {map(modalThread.messageDetails, (message) => (
                    <ItemMessage
                      item={message}
                      key={message.id}
                      openContent={openContent}
                      openThread={openThread}
                    />
                  ))}
                </PerfectScrollbar>
              </ul>
            )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex align-items-center w-100">
            <p className="m-0 me-2 text-truncate">
              {modalThread?.commonThreadData?.parsedBody[0]?.headers[
                "Reply-To"
              ] ||
                modalThread?.commonThreadData?.parsedBody[0]?.headers[
                  "Return-Path"
                ] ||
                modalThread?.commonThreadData?.parsedBody[0]?.headers["to"]}
            </p>
            <button
              type="button"
              className="btn btn-success p-1"
              onClick={() =>
                replyTo(
                  modalThread?.commonThreadData?.parsedBody[0]?.headers[
                    "Reply-To"
                  ]
                )
              }
            >
              Reply To
            </button>
            <button
              onClick={onToggleThread}
              className="btn btn-error save-user ms-1"
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <ModalSendEmail
        isOpen={modalMessage}
        onToggle={() => setModalMessage(!modalMessage)}
        customerEmail={customer ? customer.email : null}
        user={user}
        toggleCreateOrder={toggleCreateOrder}
        orderLink={orderLink}
        subject={
          modalThread &&
          (modalThread?.commonThreadData?.parsedBody[0]?.headers.Subject ||
            modalThread?.commonThreadData?.parsedBody[0]?.headers.subject)
        }
        threadId={modalThread && modalThread?.commonThreadData?.threadId}
      />
    </>
  );
}

const ItemMessage = React.memo(function ItemMessageD({
  item,
  openContent,
  openThread,
}) {
  const dateNow = Date.now();
  let time = new Date(+item.internalDate).toLocaleTimeString();
  if (dateNow - +item.internalDate > 86400000) {
    time = new Date(+item.internalDate).toLocaleDateString();
  }
  return (
    <>
      <li
        className="message_email"
        key={item.id}
        onClick={() => {
          if (item.isThread) {
            openThread(item.threadId);
          } else {
            openContent(item.id);
          }
        }}
      >
        <div className="d-flex align-items-center w-100">
          {!item.labelIds.includes("SENT") && (
            <p className="direction">Inbox</p>
          )}
          <div className="contentMes">
            <p className="subject text-truncate text-wrap">
              <strong>
                {item.parsedBody[0]?.headers?.Subject ||
                  item.parsedBody[0]?.headers?.subject}
              </strong>
              {item.isThread && <p className="isthread">Thread</p>}
            </p>
            <p className="text-truncate preview">{item.snippet}</p>
            <div className="timestamp">{time}</div>
          </div>
        </div>
      </li>
    </>
  );
});

function Attachment({ filename, base64Data, mimeType }) {
  const blob = base64ToBlob(base64Data, mimeType);
  const url = blob ? URL.createObjectURL(blob) : null;

  return (
    <li>
      <div className="overlay">
        <a href={url} download={filename}>
          Download
        </a>
      </div>

      {mimeType.startsWith("image/") ? (
        <img src={url} alt={filename} />
      ) : (
        <p>{filename}</p>
      )}
    </li>
  );
}
