import React from "react";
import { Link } from "react-router-dom";

import logomin from "../../assets/images/coolCloud/icon-menu.png";
import logo from "../../assets/images/coolCloud/White-Full-Menu.png";
import biglogo from "../../assets/images/coolCloud/Logo-Icon_clean.png";

const Home = () => {
  return (
    <div className="home_container">
      <header>
        <div className="logo">
          <img className="big" src={logo}></img>
          <img className="little" src={logomin}></img>
        </div>
        <div className="auth">
          <Link className="signup" to="/register">
            Sign up
          </Link>
          <Link className="login" to="/login">
            Log In
          </Link>
        </div>
      </header>
      <main>
        <div className="text">
          <h4>About Cool Cloud</h4>
          <p>
            <span>Cool Cloud</span> is a comprehensive cloud-based CRM system
            designed to enhance communication and streamline sales processes for
            businesses. Our platform integrates various communication channels,
            including email, text messaging, and voice calls, into a single,
            user-friendly interface.
          </p>
          <h5>Purpose of Cool Cloud</h5>
          <p>
            Cool Cloud provides businesses with the tools they need to
            efficiently manage customer relationships and sales activities. Key
            features include:
          </p>

          <ul>
            <li>
              <span>Account Management:</span> Businesses can create profiles,
              manage contacts, and assign phone numbers to sales representatives
              for seamless communication.
            </li>
            <li>
              <span>Integrated Communication: </span> Our platform allows sales
              reps to send emails, text messages, and make voice calls directly
              from the Cool Cloud interface, with advanced AI technologies to
              suggest personalized responses.
            </li>
            <li>
              <span>eCommerce Integration:</span> Connects to eCommerce
              platforms to access customer order histories and manage accounts
              efficiently.
            </li>
            <li>
              <span>Secure Transactions: </span> Utilizes PCI-compliant
              technologies to handle payment information securely.
            </li>
          </ul>
        </div>
        <div className="biglogo">
          <img src={biglogo}></img>
        </div>
      </main>
      <footer>
        <div className="prp">
          {new Date().getFullYear()} © Cool Cloud, LLC.{" "}
          <a
            href="https://app.coolcloud.com/privacy_policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div className="address">
          {/* <p className="font-size-16 text-primary">Jonathan Strietzel</p>
          <p>- CEO, Wine Chips</p> */}

          <a href="mailto:hello@coolcloud.com">hello@coolcloud.com</a>
          <p>61675 M 40, Paw Paw, MI 49079</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
