import {
  UPDATE_STATE_CAMPAING,
  GET_CAMPAINGS_SUCCESS,
  GET_CAMPAING_BY_ID_SUCCESS,
  EDIT_CAMPAING_SUCCESS,
  DELETE_CAMPAING_SUCCESS,
  API_ERROR_CAMPAINGS,
} from "./actionTypes";

const EXAMPLE_CAMPAINGS_LIST = [
  {
    id: 1,
    campaing: {
      name: "Campaing A",
      icon: "https://api.coolcloud.com/storage/44/20230726085045.png.jpg",
      lastEdited: "Last edited Aug 8, 2023",
      sendStatus: "all subscribers",
    },
    status: "Draft",
    delivered: "111",
    clickRate: "1.5%",
    CVR: "2.4%",
    unsubs: "0.2%",
    revenue: "21",
  },
  {
    id: 2,
    campaing: {
      name: "Campaing B",
      icon: "https://api.coolcloud.com/storage/44/20230726085045.png.jpg",
      lastEdited: "Last edited Aug 10, 2023",
      sendStatus: "all subscribers",
    },
    status: "Delivered",
    delivered: "555",
    clickRate: "1.5%",
    CVR: "2.4%",
    unsubs: "0.2%",
    revenue: "183",
  },
];

const INIT_STATE = {
  list: [],
  editCampaing: null,
  error: null,
};

const FlowReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE_CAMPAING:
      const data = action.payload;

      const nState = { ...state };

      if (data.hasOwnProperty("flows_list_active")) {
        if (data.flows_list_active.addNew) {
          nState.flows_list_active = [
            ...nState.flows_list_active,
            data.flows_list_active.addNew,
          ];
        } else {
          nState.flows_list_active = data.flows_list_active;
        }
      }

      return nState;

    case GET_CAMPAINGS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case DELETE_CAMPAING_SUCCESS:
      return {
        ...state,
        list: state.list.filter(({ id }) => +id !== +action.payload),
      };

    case GET_CAMPAING_BY_ID_SUCCESS:
      return {
        ...state,
        editCampaing: action.payload,
      };

    case EDIT_CAMPAING_SUCCESS:
      return {
        ...state,
        editCampaing: action.payload,
      };

    case API_ERROR_CAMPAINGS:
      console.dir(action.payload);
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default FlowReducer;
