import React from "react";

export const LineToDrop = ({
  id,
  isActive,
  isDragOver,
  onDragOver,
  onDrop,
}) => {
//   console.log("isActive", isActive);
  let fullClass = `middle`;

  if (isActive && isDragOver !== id) {
    fullClass = `middle active`;
  }

  if (isActive && isDragOver === id) {
    fullClass = `middle dragOver`;
  }

  return (
    <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      id={id}
      className={`line_flow`}
    >
      <div className="line_top" />
      <div className={fullClass} />
      <div className="line_bottom" />
    </div>
  );
};
