import React from "react";
import { Badge } from "reactstrap";

const Flow = (cell) => {
  return cell.value ? cell.value : "";
};

const Type = (cell) => {
  return cell.value ? cell.value : "";
};

const Value = (cell) => {
  return cell.value ? cell.value : "";
};

const ValueinUSD = (cell) => {
  return cell.value ? cell.value : "";
};
const Revenue = (cell) => {
  return cell.value ? "$" + cell.value : "";
};

const Status = (cell) => {
  switch (cell.value) {
    case "Live":
      return <Badge className="bg-success font-size-10">Live</Badge>;
    case "Manual":
      return <Badge className="bg-warning font-size-10">Manual</Badge>;
    case "Draft":
      return <Badge className="bg-danger font-size-10">Draft</Badge>;
  }
};

const Coin = (cell) => {
  return cell.value ? cell.value : "";
};

export { Flow, Type, Value, ValueinUSD, Status, Coin, Revenue };
