export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD"
export const CONFIRM_PASSWORD_SUCCESSFULL = "CONFIRM_PASSWORD_SUCCESSFULL"

export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL"

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const MODIFY_CURRENT_USER = "MODIFY_CURRENT_USER";
export const MODIFY_CURRENT_USER_SUCCESS = "MODIFY_CURRENT_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
