import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import withRouter from "components/Common/withRouter";

const PagesFaqs = (props) => {
  const [activeTab, setactiveTab] = useState(props.router.location.pathname);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "/faq" })}
                      onClick={() => {
                        setactiveTab("/faq");
                      }}
                    >
                      <i className="bx bx-question-mark d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">General Questions</p>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "/privacy_policy",
                      })}
                      onClick={() => {
                        setactiveTab("/privacy_policy");
                      }}
                    >
                      <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Privacy Policy</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "/support",
                      })}
                      onClick={() => {
                        setactiveTab("/support");
                      }}
                    >
                      <i className="bx bx-support d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Support</p>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "/terms_of_use",
                      })}
                      onClick={() => {
                        setactiveTab("/terms_of_use");
                      }}
                    >
                      <i className="bx bx-file d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Terms of Use</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="/faq">
                        <CardTitle className="mb-5">
                          General Questions
                        </CardTitle>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              What is Lorem Ipsum?
                            </h5>
                            <p className="text-muted">
                              New common language will be more simple and
                              regular than the existing European languages. It
                              will be as simple as occidental.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Where does it come from?
                            </h5>
                            <p className="text-muted">
                              Everyone realizes why a new common language would
                              be desirable one could refuse to pay expensive
                              translators.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              If several languages coalesce, the grammar of the
                              resulting language is more simple and regular than
                              that of the individual languages.
                            </p>
                          </div>
                        </div>
                        <div className="faq-box d-flex mb-4">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">Why do we use it?</h5>
                            <p className="text-muted">
                              Their separate existence is a myth. For science,
                              music, sport, etc, Europe uses the same
                              vocabulary.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex faq-box">
                          <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-15">
                              Where can I get some?
                            </h5>
                            <p className="text-muted">
                              To an English person, it will seem like simplified
                              English, as a skeptical Cambridge friend of mine
                              told me what Occidental
                            </p>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="/privacy_policy">
                        <CardTitle className="mb-5">
                          <h3>Privacy Policy for Cool Cloud, LLC</h3>
                          <h5>Effective Date: August 2, 2024</h5>
                        </CardTitle>
                        <CardBody>
                          <h4>1. Introduction</h4>
                          <p>
                            Welcome to Cool Cloud, LLC ("Cool Cloud"). We are
                            committed to protecting your privacy and ensuring
                            that your personal information is handled in a safe
                            and responsible manner. This Privacy Policy outlines
                            how we collect, use, store, and share your
                            information when you use our cloud-based CRM system
                            ("Cool Cloud System"). By using our services, you
                            agree to the terms of this Privacy Policy.
                          </p>
                          <h4>2. Information We Collect</h4>
                          <h5>2.1. User Accounts:</h5>
                          <p>
                            When you create an account with Cool Cloud, we
                            collect your mobile phone number for verification
                            purposes. We may also collect your name, email
                            address, and other contact details as part of the
                            registration process.
                          </p>
                          <h5>2.2. Business Information:</h5>
                          <p>
                            Businesses using Cool Cloud can create a company
                            profile and add sales representatives who manage
                            their contacts within the system. Each sales
                            representative will be assigned a new phone number
                            of their choosing for communicating with customers.
                            This number will have voicemail capability, allowing
                            customers to leave messages that sales reps can
                            retrieve from within the Cool Cloud website/app.
                            Sales representatives can connect their email
                            accounts and integrate them with the Cool Cloud
                            System.
                          </p>
                          <h5>2.3. Communication Data:</h5>
                          <p>
                            Sales representatives can email, text message, and
                            make calls to their contacts directly from the Cool
                            Cloud website/app. We collect and store these
                            communications. We use advanced AI technologies to
                            enable personalized voice communications and
                            generate suggested responses for sales reps.
                          </p>
                          <h5>2.4. Payment Information:</h5>
                          <p>
                            Cool Cloud uses PCI-compliant credit card processing
                            technologies to securely collect, tokenize, and
                            vault credit card information from customers for
                            fast and seamless future usage.
                          </p>
                          <h5>2.5. eCommerce Integration:</h5>
                          <p>
                            Cool Cloud connects to eCommerce platforms to pull
                            in customer sales data. Companies that sign up for
                            Cool Cloud have the option to connect their
                            eCommerce platform, allowing sales reps to access
                            customer order histories and manage accounts
                            efficiently. Sales reps can also generate orders
                            from within Cool Cloud, enabling fast and secure
                            credit card transactions and checkouts.
                          </p>
                          <h4>3. How We Use Your Information</h4>
                          <h5>3.1. Providing Services:</h5>
                          <p>
                            We use your information to provide, maintain, and
                            improve the Cool Cloud System and our services.Your
                            mobile phone number is used for account verification
                            and security purposes.
                          </p>
                          <h5>3.2. Communication:</h5>
                          <p>
                            We facilitate communication between sales
                            representatives and their contacts via email, text
                            message, and voice calls. Our AI technologies use
                            communication data to generate personalized
                            responses and improve the accuracy of future
                            suggestions.
                          </p>
                          <h5>3.3. Data Training:</h5>
                          <p>
                            Sales reps can correct AI-generated suggestions,
                            which helps train our AI models for better future
                            performance.
                          </p>
                          <h4>4. How We Share Your Information</h4>
                          <h5>4.1. Third-Party Services:</h5>
                          <p>
                            We may share your information with third-party
                            service providers who perform services on our
                            behalf, such as cloud hosting, data analysis, and
                            customer support.
                          </p>
                          <h5>4.2. Legal Requirements:</h5>
                          <p>
                            We may disclose your information if required by law
                            or in response to valid requests by public
                            authorities (e.g., a court or a government agency).
                          </p>
                          <h5>4.3. Business Transfers:</h5>
                          <p>
                            In the event of a merger, acquisition, or sale of
                            all or a portion of our assets, your information may
                            be transferred as part of the transaction.
                          </p>
                          <h4>5. Data Security</h4>
                          <p>
                            We implement appropriate technical and
                            organizational measures to protect your personal
                            information from unauthorized access, disclosure,
                            alteration, and destruction. This includes the use
                            of Secure Socket Layer (SSL) encryption technology
                            to safeguard the transmission of your data between
                            your device and our servers. However, no security
                            measures are completely foolproof, and we cannot
                            guarantee absolute security.
                          </p>
                          <h4>6. Your Rights and Choices</h4>
                          <h5>6.1. Access and Correction:</h5>
                          <p>
                            You have the right to access and update your
                            personal information. You can do this through your
                            account settings or by contacting us directly.
                          </p>
                          <h5>6.2. Data Deletion:</h5>
                          <p>
                            You may request the deletion of your account and
                            personal information by contacting us. Please note
                            that we may retain certain information as required
                            by law or for legitimate business purposes.
                          </p>
                          <h4>7. Use of Google User Data</h4>
                          <p>
                            Cool Cloud's use of Google user data is limited to
                            the practices disclosed in this Privacy Policy and
                            conforms with Google’s Limited Use Requirements. We
                            access, use, store, and share Google user data to:
                            Provide email integration within the Cool Cloud
                            System. Facilitate communication and improve user
                            experience. Ensure the security and proper
                            functioning of our services.
                          </p>
                          <h4>8. Changes to This Privacy Policy</h4>
                          <p>
                            We may update this Privacy Policy from time to time.
                            We will notify you of any changes by posting the new
                            Privacy Policy on our website and app. You are
                            advised to review this Privacy Policy periodically
                            for any changes.
                          </p>
                          <h4>9. Contact Us</h4>
                          <p>
                            <strong>Cool Cloud, LLC</strong>
                            <br />
                            Email:{" "}
                            <a href="mailto:hello@coolcloud.com">
                              hello@coolcloud.com
                            </a>
                            <br />
                            Address: 61675 M 40, Paw Paw, MI 49079
                          </p>
                          <p>Thank you for using Cool Cloud!</p>

                          <p>
                            Note: These Terms of Use will be linked on our
                            homepage and the app’s OAuth consent screen to
                            ensure transparency and compliance with our usage
                            policies.
                          </p>
                        </CardBody>
                      </TabPane>
                      <TabPane tabId="/support">
                        <CardTitle className="mb-5">Support</CardTitle>
                      </TabPane>
                      <TabPane tabId="/terms_of_use">
                        <CardTitle className="mb-5">
                          <h3>Terms of Use for Cool Cloud, LLC</h3>
                          <h5>Effective Date: August 2, 2024</h5>
                        </CardTitle>
                        <CardBody>
                          <h4>1. Acceptance of Terms</h4>
                          <p>
                            By accessing and using the services provided by Cool
                            Cloud, LLC ("Cool Cloud"), including our website,
                            mobile application, and any related services
                            (collectively, the "Services"), you agree to comply
                            with and be bound by these Terms of Use ("Terms").
                            If you do not agree to these Terms, please do not
                            use our Services.
                          </p>
                          <h4>2. Services Provided</h4>
                          <p>
                            Cool Cloud offers a cloud-based CRM system that
                            allows businesses to manage their contacts,
                            communications, and sales activities. Our Services
                            include, but are not limited to, email and text
                            message integration, voice communications, eCommerce
                            platform integration, and advanced AI technologies
                            for personalized communications and suggested
                            responses.
                          </p>
                          <h4>3. User Accounts</h4>
                          <h5>3.1. Account Creation:</h5>
                          <p>
                            Users must create an account to access the Services.
                            During the registration process, you must provide
                            accurate and complete information, including a
                            mobile phone number for verification purposes. Each
                            sales representative will be assigned a new phone
                            number for communicating with customers, which will
                            include voicemail capability.
                          </p>
                          <h5>3.2. Account Responsibility:</h5>
                          <p>
                            You are responsible for maintaining the
                            confidentiality of your account information and
                            password. You agree to notify us immediately of any
                            unauthorized use of your account.
                          </p>
                          <h4>4. Use of Services</h4>
                          <h5>4.1. License:</h5>
                          <p>
                            Cool Cloud grants you a limited, non-exclusive,
                            non-transferable, and revocable license to access
                            and use the Services for your internal business
                            purposes, subject to these Terms.
                          </p>
                          <h5>4.2. Prohibited Uses:</h5>
                          <p>
                            You agree not to use the Services for any unlawful
                            purpose or in a manner that could harm or impair the
                            Services or interfere with any other party's use of
                            the Services. Prohibited activities include, but are
                            not limited to, unauthorized access, data mining,
                            any form of hacking, and the use of AI technologies
                            or the overall Cool Cloud CRM platform to
                            impersonate, spam, or defraud any individual. A
                            breach of this will result in Cool Cloud working
                            with authorities.
                          </p>
                          <h4>5. Data Security and Privacy</h4>
                          <h5>5.1. Data Collection and Use:</h5>
                          <p>
                            Our collection, use, and storage of your personal
                            information are governed by our Privacy Policy,
                            which is incorporated into these Terms by reference.
                            By using the Services, you consent to the data
                            practices described in our Privacy Policy.
                          </p>
                          <h5>5.2. Data Security:</h5>
                          <p>
                            We implement appropriate technical and
                            organizational measures to protect your personal
                            information from unauthorized access, disclosure,
                            alteration, and destruction. This includes the use
                            of Secure Socket Layer (SSL) encryption technology
                            to safeguard the transmission of your data.
                          </p>
                          <h4>6. Payment and eCommerce Integration</h4>
                          <h5>6.1. Payment Processing:</h5>
                          <p>
                            Cool Cloud uses PCI-compliant credit card processing
                            technologies to securely collect, tokenize, and
                            vault credit card information for fast and seamless
                            future usage.
                          </p>
                          <h5>6.2. eCommerce Integration:</h5>
                          <p>
                            Companies that sign up for Cool Cloud may connect
                            their eCommerce platforms to the Services, allowing
                            sales representatives to access customer order
                            histories and manage accounts efficiently. Sales
                            reps can generate orders from within Cool Cloud,
                            enabling fast and secure credit card transactions
                            and checkouts.
                          </p>

                          <h4>7. Intellectual Property</h4>
                          <h5>7.1. Ownership:</h5>
                          <p>
                            All content, features, and functionality of the
                            Services, including but not limited to text,
                            graphics, logos, and software, are the exclusive
                            property of Cool Cloud and are protected by
                            copyright, trademark, and other intellectual
                            property laws.
                          </p>

                          <h5>7.2. Rights to AI Voice:</h5>
                          <p>
                            If a sales representative creates an AI voice, Cool
                            Cloud is granted a non-exclusive, royalty-free,
                            worldwide, perpetual license to use, reproduce, and
                            modify these AI voices within the company account
                            under which the sales representative's account is
                            registered. This usage is restricted to helping the
                            sales representative scale their sales activities
                            within their company and improving the functionality
                            of the Services for that specific company.
                          </p>

                          <h5>7.3. Restrictions:</h5>
                          <p>
                            You may not modify, reproduce, distribute, create
                            derivative works of, publicly display, or perform
                            any content from the Services without our prior
                            written consent.
                          </p>

                          <h4>8. Disclaimers and Limitation of Liability</h4>
                          <h5>8.1. Disclaimers:</h5>
                          <p>
                            The Services are provided "as is" and "as available"
                            without any warranties of any kind, either express
                            or implied. Cool Cloud disclaims all warranties,
                            including but not limited to, implied warranties of
                            merchantability, fitness for a particular purpose,
                            and non-infringement.
                          </p>

                          <h5>8.2. Limitation of Liability:</h5>
                          <p>
                            To the fullest extent permitted by law, Cool Cloud
                            shall not be liable for any indirect, incidental,
                            special, consequential, or punitive damages,
                            including but not limited to, loss of profits, data,
                            use, goodwill, or other intangible losses resulting
                            from your use of the Services.
                          </p>

                          <h4>9. Indemnification</h4>
                          <p>
                            You agree to indemnify, defend, and hold harmless
                            Cool Cloud, its affiliates, officers, directors,
                            employees, agents, and licensors from and against
                            any claims, liabilities, damages, judgments, awards,
                            losses, costs, expenses, or fees (including
                            reasonable attorneys' fees) arising out of or
                            relating to your violation of these Terms or your
                            use of the Services.
                          </p>

                          <h4>10. Termination</h4>
                          <h5>10.1. Termination by You:</h5>
                          <p>
                            You may terminate your account at any time by
                            contacting us. Upon termination, your right to use
                            the Services will immediately cease.
                          </p>

                          <h5>10.2. Termination by Cool Cloud:</h5>
                          <p>
                            Cool Cloud may terminate or suspend your account and
                            access to the Services, without prior notice or
                            liability, for any reason, including but not limited
                            to, a breach of these Terms.
                          </p>

                          <h4>11. Governing Law and Dispute Resolution</h4>
                          <p>
                            These Terms shall be governed by and construed in
                            accordance with the laws of the State of Michigan,
                            without regard to its conflict of law principles.
                            Any disputes arising out of or relating to these
                            Terms or the Services shall be resolved through
                            binding arbitration in accordance with the rules of
                            the American Arbitration Association.
                          </p>

                          <h4>12. Changes to the Terms</h4>
                          <p>
                            We may update these Terms from time to time. We will
                            notify you of any changes by posting the new Terms
                            on our website and app. You are advised to review
                            these Terms periodically for any changes. Your
                            continued use of the Services after the changes
                            constitutes your acceptance of the new Terms.
                          </p>

                          <h4>13. Contact Us</h4>
                          <p>
                            If you have any questions about these Terms, please
                            contact us at:
                          </p>
                          <p>
                            <strong>Cool Cloud, LLC</strong>
                            <br />
                            Email:{" "}
                            <a href="mailto:hello@coolcloud.com">
                              hello@coolcloud.com
                            </a>
                            <br />
                            Address: 61675 M 40, Paw Paw, MI 49079
                          </p>

                          <p>Thank you for using Cool Cloud!</p>

                          <p>
                            Note: These Terms of Use will be linked on our
                            homepage and the app’s OAuth consent screen to
                            ensure transparency and compliance with our usage
                            policies.
                          </p>
                        </CardBody>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PagesFaqs);

const terms = `

7. Intellectual Property

7.1. Ownership:

All content, features, and functionality of the Services, including but not limited to text, graphics, logos, and software, are the exclusive property of Cool Cloud and are protected by copyright, trademark, and other intellectual property laws.

7.2. Rights to AI Voice:

If a sales representative creates an AI voice, Cool Cloud is granted a non-exclusive, royalty-free, worldwide, perpetual license to use, reproduce, and modify these AI voices within the company account under which the sales representative's account is registered. This usage is restricted to helping the sales representative scale their sales activities within their company and improving the functionality of the Services for that specific company.

7.3. Restrictions:

You may not modify, reproduce, distribute, create derivative works of, publicly display, or perform any content from the Services without our prior written consent.
8. Disclaimers and Limitation of Liability

8.1. Disclaimers:

The Services are provided "as is" and "as available" without any warranties of any kind, either express or implied. Cool Cloud disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
8.2. Limitation of Liability:

To the fullest extent permitted by law, Cool Cloud shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses resulting from your use of the Services.
9. Indemnification

You agree to indemnify, defend, and hold harmless Cool Cloud, its affiliates, officers, directors, employees, agents, and licensors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Services.

10. Termination

10.1. Termination by You:

You may terminate your account at any time by contacting us. Upon termination, your right to use the Services will immediately cease.
10.2. Termination by Cool Cloud:

Cool Cloud may terminate or suspend your account and access to the Services, without prior notice or liability, for any reason, including but not limited to, a breach of these Terms.
11. Governing Law and Dispute Resolution

These Terms shall be governed by and construed in accordance with the laws of the State of Michigan, without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.

12. Changes to the Terms

We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website and app. You are advised to review these Terms periodically for any changes. Your continued use of the Services after the changes constitutes your acceptance of the new Terms.

13. Contact Us

If you have any questions about these Terms, please contact us at:

Cool Cloud, LLC
Email: hello@coolcloud.com
Address: 61675 M 40, Paw Paw, MI 49079

Thank you for using Cool Cloud!

Note: These Terms of Use will be linked on our homepage and the app’s OAuth consent screen to ensure transparency and compliance with our usage policies.
`;
